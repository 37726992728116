import React, { useState } from 'react';
import styles from './PresentationsListInfo.module.scss';

const PresentationsListInfo = ({
    presentations,
}: {
    presentations: string[];
}) => {
    const [showAll, setShowAll] = useState(false);
    const presentationsToShow = showAll
        ? presentations
        : presentations.slice(0, 5);

    return (
        <div className={styles.presentationsListInfo}>
            {presentationsToShow.map((presentation, index) => (
                <span
                    key={index}
                    className={styles.presentationsListInfo__element}
                >
                    {presentation}
                </span>
            ))}
            {presentations.length > 5 && !showAll && (
                <span
                    className={styles.presentationsListInfo__button}
                    onClick={() => setShowAll(true)}
                >
                    Еще {presentations.length - 5}
                </span>
            )}
        </div>
    );
};

export default PresentationsListInfo;
