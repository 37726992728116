import React, { useCallback } from 'react';
import styles from './PhotoCollections.module.scss';
import { toast } from 'react-toastify';
import PhotoCollectionsTable from './PhotoCollectionsTable';
import { PhotoCollectionApis } from '@APIs/photo-collection.apis';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectSelectedPatient } from '@store/feature/patient.slice';
import PhotoCollectionsHeader from '@pages/PhotoCollections/PhotoCollectionsHeader';
import {
    removeCollection,
    selectCollections,
    selectCollectionsLoadStatus,
    setCollections,
} from '@feature/collections.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';

export const PhotoCollections = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const collections = useAppSelector(selectCollections);
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const isCollectionsLoading = useAppSelector(selectCollectionsLoadStatus);

    const createCollection = useCallback(
        (id: string) => {
            if (selectedPatient) {
                toast.loading('Создаем фотоколлекцию');
                PhotoCollectionApis.createCollection(selectedPatient.id, id)
                    .then(() => {
                        PhotoCollectionApis.getAll(selectedPatient?.id).then(
                            // todo: переписать на добавление коллекции без запроса, когда бэк будет возвращать коллекцию при создании
                            ({ data }) => {
                                dispatch(setCollections(data));
                                toast.dismiss();
                            }
                        );
                    })
                    .catch((error) => {
                        toast.dismiss();
                        if (error.response) {
                            toast.error(error.response.data.message);
                        }
                    });
            }
        },
        [selectedPatient]
    );

    const deleteCollection = (id: string) => {
        dispatch(removeCollection(id));
    };

    return (
        <div className={styles.Container}>
            <PhotoCollectionsHeader createCollection={createCollection} />
            <PhotoCollectionsTable
                collections={collections}
                isLoading={isCollectionsLoading === 'loading'}
                onRemoval={deleteCollection}
            />
        </div>
    );
};
