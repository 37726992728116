import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stage } from 'react-konva';
import style from './RectangleKonvaBase.module.scss';

import PresentationEditRectToolbar, {
    PresentationToolEnum,
} from '@pages/PresentationEdit/components/PresentationEditRectToolbar/PresentationEditRectToolbar';
import {
    ROTATION_STEP,
    TOUCHPAD_ROTATION_FACTOR,
} from '@pages/PresentationEdit/constants/constants';
import { ITransform } from '@interfaces/presentation.interface';

export function RectangleKonvaBase({
    isActive,
    initialTransform,
    onTransformChanges,
    children,
    isEditable,
}: {
    isActive: boolean;
    isEditable: boolean;
    initialTransform?: ITransform; // Transform from firebase
    onTransformChanges?: (transform: ITransform) => void;
    children?: JSX.Element | JSX.Element[] | null;
}): JSX.Element {
    const [selectedTool, setSelectedTool] = useState(PresentationToolEnum.MOVE);

    // Containers refs
    const container = useRef<HTMLDivElement>(null);
    const stage = useRef<Konva.Stage>(null);

    // Drag properties
    let isDragged = false;
    let lastPointPosition = { x: 0, y: 0 };
    let lastPosition = { x: 0, y: 0 };
    const reflection = useRef<{
        x: number;
        y: number;
    }>(
        initialTransform?.reflection ?? {
            x: 1,
            y: 1,
        }
    );

    const isDraggable = () => {
        return (
            isActive && selectedTool == PresentationToolEnum.MOVE && isEditable
        );
    };

    useEffect(() => {
        const layer = stage.current?.children && stage.current.children[0];
        const image = layer?.children && layer.children[0];

        const reflectionX = initialTransform?.reflection?.x || 1;
        const reflectionY = initialTransform?.reflection?.y || 1;

        reflection.current.x = reflectionX;
        reflection.current.y = reflectionY;

        image?.to({
            scaleX: reflectionX * image.scaleX(),
            scaleY: reflectionY * image.scaleY(),
        });
    }, []);

    // Full screen and fix size on resize
    useEffect(() => {
        function fix() {
            if (container.current) {
                stage.current?.size({
                    width: container.current.parentElement?.offsetWidth ?? 1,
                    height: container.current.parentElement?.offsetHeight ?? 1,
                });
            }
        }

        fix();

        window.addEventListener('resize', fix);
        return () => window.removeEventListener('resize', fix);
    }, []);

    useEffect(() => {
        // Set initialTransform for stage and rotation for image
        const oldPosition = initialTransform?.transform ?? { x: 0, y: 0 };
        const oldScale = initialTransform?.scale ?? 1.0;
        const oldRotation = initialTransform?.rotate ?? 0.0;
        const layer = stage.current?.children && stage.current.children[0];
        stage?.current?.position(oldPosition);

        stage?.current?.position({
            x: oldPosition.x === 0 ? 0 : stage.current.width() / oldPosition.x,
            y: oldPosition.y === 0 ? 0 : stage.current.height() / oldPosition.y,
        });
        stage?.current?.scale({ x: oldScale, y: oldScale });

        layer?.rotation(oldRotation);
    }, [stage]);

    const zoom = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const scaleFactor = 1.08;
        const delta = e.evt.deltaY
            ? e.evt.deltaY / 40
            : e.evt.detail
            ? -e.evt.detail
            : 0;
        const factor = Math.pow(scaleFactor, delta);

        const target = stage.current;
        const oldScale = stage.current?.scaleX() ?? 1;
        const pointer = stage.current?.getPointerPosition() ?? { x: 0, y: 0 };

        const mousePointTo = {
            x: (pointer.x - (target?.x() ?? 0)) / oldScale,
            y: (pointer.y - (target?.y() ?? 0)) / oldScale,
        };

        let direction = e.evt.deltaY > 0 ? 1 : -1;

        if (e.evt.ctrlKey) {
            direction = -direction;
        }

        const newScale = oldScale / factor;

        stage.current?.scale({ x: newScale, y: newScale });

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        stage.current?.position(newPos);

        updateTransform();
    };

    const move = (e: KonvaEventObject<WheelEvent>) => {
        const delta = { x: -e.evt.deltaX * 0.36, y: -e.evt.deltaY * 0.36 };
        const position = stage.current?.position() ?? { x: 0, y: 0 };

        stage.current?.position({
            x: position.x + delta.x,
            y: position.y + delta.y,
        });

        updateTransform();

        e.evt.preventDefault();
    };

    const rotateWithDelta = (delta: { x: number; y: number }) => {
        const layer = stage.current?.children && stage.current.children[0];
        const size = layer?.size() ?? { width: 0, height: 0 };

        // sin + cos require radians
        const angleRadians = (delta.y * Math.PI) / 180;

        const x =
            size.width / 2 +
            ((stage.current?.x() ?? 0) - size.width / 2) *
                Math.cos(angleRadians) -
            ((stage.current?.y() ?? 0) - size.height / 2) *
                Math.sin(angleRadians);
        const y =
            size.height / 2 +
            ((stage.current?.x() ?? 0) - size.width / 2) *
                Math.sin(angleRadians) +
            ((stage.current?.y() ?? 0) - size.height / 2) *
                Math.cos(angleRadians);

        // move the rotated shape in relation to the rotation point.
        stage.current?.position({ x: x, y: y });

        // rotate the shape in place around its natural rotation point
        layer?.rotation(layer?.rotation() + delta.y);

        updateTransform();
    };

    const rotate = (e: KonvaEventObject<WheelEvent>) => {
        const delta = {
            x: (-e.evt.deltaX / Math.abs(e.evt.deltaX)) * ROTATION_STEP,
            y: (-e.evt.deltaY / Math.abs(e.evt.deltaY)) * ROTATION_STEP,
        };

        const isTouchpad =
            Math.abs(e.evt.deltaY) < TOUCHPAD_ROTATION_FACTOR &&
            Math.abs(e.evt.deltaX) < TOUCHPAD_ROTATION_FACTOR;

        if (isTouchpad) {
            delta.x = -e.evt.deltaX * 0.036;
            delta.y = -e.evt.deltaY * 0.036;
        }

        rotateWithDelta(delta);

        e.evt.preventDefault();
    };

    const onWheel = (e: KonvaEventObject<WheelEvent>) => {
        if (isActive && selectedTool == PresentationToolEnum.ROTATE) {
            rotate(e);

            return;
        }

        if (!isDraggable()) return;

        if (selectedTool === PresentationToolEnum.MOVE || e.evt.ctrlKey) {
            zoom(e);
            return;
        }

        move(e);
    };

    // Drag mouse
    const onMouseUp = () => {
        isDragged = false;
    };
    const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        if (!isDraggable()) return;

        isDragged = true;

        lastPointPosition = stage.current?.getPointerPosition() ?? {
            x: 0,
            y: 0,
        };
        lastPosition = stage.current?.position() ?? { x: 0, y: 0 };
    };

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (!isDraggable()) return;

        if (isDragged) {
            const pointPosition = stage.current?.getPointerPosition() ?? {
                x: 0,
                y: 0,
            };

            stage.current?.position({
                x: lastPosition.x + (pointPosition.x - lastPointPosition.x),
                y: lastPosition.y + (pointPosition.y - lastPointPosition.y),
            });

            updateTransform();
        }
    };

    const updateTransform = useCallback(
        (reflection?: { x: number; y: number }) => {
            if (onTransformChanges) {
                if (stage.current) {
                    const newPosition = stage.current.position() ?? {
                        x: 0,
                        y: 0,
                    };
                    const layer =
                        stage.current.children && stage.current.children[0];

                    const data = {
                        scale: stage.current.scale()?.x ?? 1.0,
                        rotate: layer?.rotation() ?? 0,
                        transform: {
                            x: stage.current.width() / newPosition.x,
                            y: stage.current.height() / newPosition.y,
                        },
                        reflection,
                    };
                    onTransformChanges(data);
                }
            }
        },
        [reflection]
    );

    const executeCommand = (command: PresentationToolEnum) => {
        if (
            command == PresentationToolEnum.ZOOM ||
            command == PresentationToolEnum.OUT
        ) {
            const oldScale = stage.current?.scale()?.x ?? 1.0;
            let newScale = oldScale * 1.1;

            if (command == PresentationToolEnum.OUT) {
                newScale = oldScale * (1 / 1.1);
            }

            const target = stage.current;
            const pointer = {
                x: (stage.current?.width() ?? 0) / 2 ?? 0,
                y: (stage.current?.height() ?? 0) / 2 ?? 0,
            };

            const mousePointTo = {
                x: (pointer.x - (target?.x() ?? 0)) / oldScale,
                y: (pointer.y - (target?.y() ?? 0)) / oldScale,
            };

            stage.current?.scale({ x: newScale, y: newScale });

            const newPos = {
                x: pointer.x - mousePointTo.x * newScale,
                y: pointer.y - mousePointTo.y * newScale,
            };
            stage.current?.position(newPos);

            updateTransform();
        } else if (command == PresentationToolEnum.RESET) {
            const layer = stage.current?.children && stage.current.children[0];

            const image = layer?.children && layer.children[0];

            stage?.current?.position({ x: 0, y: 0 });
            stage?.current?.scale({ x: 1.0, y: 1.0 });
            layer?.rotation(0);

            if (reflection.current.x < 0) {
                reflection.current.x *= -1;
                image?.to({
                    scaleX: -1 * image.scaleX(),
                });
            }
            if (reflection.current.y < 0) {
                reflection.current.y *= -1;
                image?.to({
                    scaleY: -1 * image.scaleY(),
                });
            }

            updateTransform();
        } else if (command == PresentationToolEnum.FLIPHOR) {
            const layer = stage.current?.children && stage.current.children[0];

            const image = layer?.children && layer.children[0];

            reflection.current.x *= -1;

            image?.to({
                scaleX: -1 * image.scaleX(),
            });

            updateTransform(reflection.current);
        } else if (command == PresentationToolEnum.FLIPVERT) {
            const layer = stage.current?.children && stage.current.children[0];

            const image = layer?.children && layer.children[0];

            reflection.current.y *= -1;

            image?.to({
                scaleY: -1 * image.scaleY(),
            });

            updateTransform(reflection.current);
        } else {
            setSelectedTool(command);
        }
    };

    return (
        <div ref={container} className={style.Canvas}>
            {isActive && isEditable && (
                <PresentationEditRectToolbar
                    selectedTool={selectedTool}
                    onSelectElement={(element) => {
                        executeCommand(element);
                    }}
                />
            )}
            <Stage
                draggable={isDraggable()}
                width={100}
                height={100}
                ref={stage}
                onWheel={onWheel}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onDragMove={onMouseMove}
            >
                {children}
            </Stage>
        </div>
    );
}
