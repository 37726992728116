import React, { useCallback, useRef, useEffect } from 'react';
import { Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

interface DraggableCircleProps {
    x: number;
    y: number;
    onDragMove: (e: KonvaEventObject<DragEvent>) => void;
    onDragEnd: (e: KonvaEventObject<DragEvent>) => void;
}

const PointComponent: React.FC<DraggableCircleProps> = ({
    x,
    y,
    onDragMove,
    onDragEnd,
}) => {
    const circleRef = useRef<Konva.Circle>(null);

    const handleMouseEnter = useCallback((e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
            container.style.cursor = 'pointer';
        }
    }, []);

    const handleMouseLeave = useCallback((e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
            container.style.cursor = 'default';
        }
    }, []);

    useEffect(() => {
        if (circleRef.current) {
            circleRef.current.hitFunc((context: Konva.Context) => {
                context.beginPath();
                context.arc(0, 0, 5, 0, Math.PI * 2, true);
                context.closePath();
                context.fillStrokeShape(circleRef.current as Konva.Shape);
            });
        }
    }, []);

    const handleDragMove = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            const stage = e.target.getStage();
            if (!stage) return;

            const stageWidth = stage.width();
            const stageHeight = stage.height();

            e.target.x(Math.max(0, Math.min(stageWidth, e.target.x())));
            e.target.y(Math.max(0, Math.min(stageHeight, e.target.y())));

            onDragMove(e);
        },
        [onDragMove]
    );

    return (
        <Circle
            ref={circleRef}
            x={x}
            y={y}
            radius={3}
            fill="#3b82f6"
            draggable
            onDragMove={handleDragMove}
            onDragEnd={onDragEnd}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        />
    );
};

export default PointComponent;
