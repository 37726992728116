import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import style from './PresentationEditRectToolbar.module.scss';

import TrgMove from '@images/trg_move.svg';
import TrgZoom from '@images/trg_zoom.svg';
import TrgOut from '@images/trg_out.svg';
import PresentationRotate from '@images/presentation_rotate.svg';
import PresentationReset from '@images/presentation_reset.svg';
import PresentationPaint from '@images/presentation_paint.svg';
import PresentationFlipHor from '@images/presentation_flip_vert.svg';
import PresentationFlipVert from '@images/presentation_flip_hor.svg';
import ai from '@images/ai.svg';

import TrgToolButton from '@components/TrgEdit/edit-tools/TrgToolButton';

export enum PresentationToolEnum {
    // Не удалять, таска DLB-1345
    // PAINT = 'PAINT',
    MOVE = 'MOVE',
    ROTATE = 'ROTATE',
    IMAGE = 'IMAGE',
    ZOOM = 'ZOOM',
    OUT = 'OUT',
    RESET = 'RESET',
    FLIPVERT = 'FLIPVERTICALLY',
    FLIPHOR = 'FLIPHORIZONTALLY',
}

export default function PresentationEditRectToolbar({
    selectedTool,
    onSelectElement,
}: {
    selectedTool: PresentationToolEnum;
    onSelectElement: (active: PresentationToolEnum) => void;
}): JSX.Element {
    const divs = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<CSSProperties>({});

    useEffect(() => {
        // Fix out of window
        const ti = divs.current?.getBoundingClientRect().right ?? 0;
        if (ti > window.innerWidth) {
            setStyles({ position: 'fixed', right: 0 } as CSSProperties);
        }

        return undefined;
    }, [divs]);

    return (
        <div ref={divs} className={style.ToolBar} style={styles}>
            {/*Не удалять, таска DLB-1345*/}
            {/*<TrgToolButton*/}
            {/*    icon={PresentationPaint}*/}
            {/*    tip={'Скоро - Рисование'}*/}
            {/*    isEnabled={false}*/}
            {/*    isActive={selectedTool == PresentationToolEnum.PAINT}*/}
            {/*    onClick={() => {*/}
            {/*        onSelectElement(PresentationToolEnum.PAINT);*/}
            {/*    }}*/}
            {/*/>*/}
            <TrgToolButton
                icon={TrgMove}
                tip={'Перемещать'}
                isEnabled={true}
                isActive={selectedTool == PresentationToolEnum.MOVE}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.MOVE);
                }}
            />
            <TrgToolButton
                icon={PresentationRotate}
                tip={'Повернуть'}
                isEnabled={true}
                isActive={selectedTool == PresentationToolEnum.ROTATE}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.ROTATE);
                }}
            />
            <TrgToolButton
                icon={ai}
                tip={'Скоро - AI обработка'}
                isEnabled={false}
                isActive={selectedTool == PresentationToolEnum.IMAGE}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.IMAGE);
                }}
            />
            <TrgToolButton
                icon={PresentationReset}
                tip={'Сбросить'}
                isEnabled={true}
                isActive={selectedTool == PresentationToolEnum.RESET}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.RESET);
                }}
            />
            <div className="space-tool" />
            <div className="space-tool" />
            <div className="space-tool" />
            <TrgToolButton
                icon={PresentationFlipVert}
                tip={'Отразить по вертикали'}
                isEnabled={true}
                isActive={false}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.FLIPVERT);
                }}
            />
            <TrgToolButton
                icon={PresentationFlipHor}
                tip={'Отразить по горизонтали'}
                isEnabled={true}
                isActive={false}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.FLIPHOR);
                }}
            />
            <TrgToolButton
                icon={TrgZoom}
                tip={'Приблизить'}
                isEnabled={true}
                isActive={false}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.ZOOM);
                }}
            />
            <TrgToolButton
                icon={TrgOut}
                tip={'Отдалить'}
                isEnabled={true}
                isActive={false}
                onClick={() => {
                    onSelectElement(PresentationToolEnum.OUT);
                }}
            />
        </div>
    );
}
