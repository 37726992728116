import React, { useRef, useState, useCallback } from 'react';
import { Line, Group } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { ILine } from '@interfaces/presentationEditing.interface';
import useCursor from '@hooks/useCursor';
import Konva from 'konva';
import PointComponent from '@pages/PresentationEdit/components/EditingLayer/PointComponent/PointComponent';

interface LineProps {
    shapeProps: ILine;
    isSelected: boolean;
    onSelect: () => void;
    onChange: (newAttrs: ILine) => void;
    dash?: number[];
}

const LineComponent: React.FC<LineProps> = ({
    shapeProps,
    isSelected,
    onSelect,
    onChange,
    dash,
}) => {
    const shapeRef = useRef<Konva.Line>(null);
    const groupRef = useRef<Konva.Group>(null);

    const [tempPoints, setTempPoints] = useState(shapeProps.points);
    const moveDistance = useRef<[number, number, number, number]>([0, 0, 0, 0]);

    useCursor(shapeRef, isSelected);

    const handlePointDragMove = useCallback(
        (index: number, e: KonvaEventObject<DragEvent>) => {
            if (shapeRef.current) {
                const newPoints = [...tempPoints];
                newPoints[index] = e.target.x();
                newPoints[index + 1] = e.target.y();
                setTempPoints(newPoints);
            }
            e.cancelBubble = true;
        },
        [tempPoints]
    );

    const handlePointDragEnd = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            onChange({
                ...shapeProps,
                points: tempPoints,
            });
            e.cancelBubble = true;
        },
        [onChange, shapeProps, tempPoints]
    );

    const handleGroupDragStart = useCallback(() => {
        const group = groupRef.current;
        if (group) {
            const stage = group.getStage();
            const stageWidth = stage?.width() ?? 0;
            const stageHeight = stage?.height() ?? 0;
            moveDistance.current = [
                -Math.min(tempPoints[0], tempPoints[2]),
                stageWidth - Math.max(tempPoints[0], tempPoints[2]),
                -Math.min(tempPoints[1], tempPoints[3]),
                stageHeight - Math.max(tempPoints[1], tempPoints[3]),
            ];
        }
    }, [tempPoints]);

    const handleGroupDragMove = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            const group = groupRef.current;
            if (group) {
                const newX = group.x() + e.evt.movementX;
                const newY = group.y() + e.evt.movementY;

                if (
                    newX >= moveDistance.current[0] &&
                    newX <= moveDistance.current[1] &&
                    newY >= moveDistance.current[2] &&
                    newY <= moveDistance.current[3]
                ) {
                    group.position({ x: newX, y: newY });
                } else {
                    const boundedX = Math.max(
                        moveDistance.current[0],
                        Math.min(moveDistance.current[1], newX)
                    );
                    const boundedY = Math.max(
                        moveDistance.current[2],
                        Math.min(moveDistance.current[3], newY)
                    );
                    group.position({ x: boundedX, y: boundedY });
                }
                group.getLayer()?.batchDraw();
            }
        },
        []
    );

    const handleGroupDragEnd = useCallback(() => {
        if (groupRef.current) {
            const groupPosition = groupRef.current.position();
            const newPoints = tempPoints.map((point, index) =>
                index % 2 === 0
                    ? point + groupPosition.x
                    : point + groupPosition.y
            );

            onChange({
                ...shapeProps,
                points: newPoints,
            });

            setTempPoints(newPoints);
            groupRef.current.position({ x: 0, y: 0 });
        }
    }, [onChange, shapeProps, tempPoints]);

    return (
        <React.Fragment>
            <Group
                ref={groupRef}
                draggable
                onDragStart={handleGroupDragStart}
                onDragMove={handleGroupDragMove}
                onDragEnd={handleGroupDragEnd}
            >
                <Line
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    strokeWidth={shapeProps.width}
                    points={tempPoints}
                    stroke={shapeProps.color}
                    dash={dash}
                />
                {isSelected && (
                    <React.Fragment>
                        <PointComponent
                            x={tempPoints[0]}
                            y={tempPoints[1]}
                            onDragMove={(e) => handlePointDragMove(0, e)}
                            onDragEnd={handlePointDragEnd}
                        />
                        <PointComponent
                            x={tempPoints[2]}
                            y={tempPoints[3]}
                            onDragMove={(e) => handlePointDragMove(2, e)}
                            onDragEnd={handlePointDragEnd}
                        />
                    </React.Fragment>
                )}
            </Group>
        </React.Fragment>
    );
};

export default LineComponent;
