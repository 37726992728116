import React from 'react';
import styles from './RowElement.module.scss';
import { ReactComponent as Eye } from '@images/presentationEye.svg';
import { ReactComponent as EyeClosed } from '@images/presentationEyeClosed.svg';

const RowElement = ({
    isHidden,
    isSelected,
    onClick,
    onEyeClick,
    index,
}: {
    isHidden: boolean;
    isSelected: boolean;
    onClick: () => void;
    onEyeClick: () => void;
    index: number;
}): JSX.Element => {
    return (
        <div
            className={
                isSelected ? styles.rowElement_active : styles.rowElement
            }
            onClick={onClick}
            key={index}
        >
            <div className={styles.rowElement__row}>
                <div className={styles.rowElement__fileIcon} />
                <p className={styles.rowElement__title}>Слайд {index + 1}</p>
                <div
                    className={styles.rowElement__eyeIcon}
                    onClick={(e) => {
                        e.stopPropagation();
                        onEyeClick();
                    }}
                >
                    {isHidden ? <EyeClosed /> : <Eye />}
                </div>
            </div>
            <div className={styles.rowElement__separator} />
        </div>
    );
};

export default RowElement;
