import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import style from './PhotoMatching.module.scss';
import { IPhotoFile } from '@interfaces/photo.interface';
import LoadingIndicator from '@components/patient/patientinfo/LoadingIndicator';
import {
    photoCollectionAPIs,
    PhotoCollectionApis,
} from '@APIs/photo-collection.apis';
import { ICollectionElement } from '@interfaces/collection-element.interface';
import { ElementInfo } from '@pages/PhotoMatching/components/ElementInfo/ElementInfo';
import { StatusInfo } from '@pages/PhotoMatching/components/StatusInfo/StatusInfo';
import { ROUTES } from '@constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    selectSelectedPatientStatus,
    setPatientCollectionStatus,
    updateCollectionStatusById,
} from '@store/feature/patient.slice';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { collectionTitles } from '@utils/photoMatching';
import { StorageModal } from '@components/storageModal/StorageModal';
import { createStatus } from '@utils/photo-collections';
import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import PhotoMatchingArea from './components/PhotoMatchingArea/PhotoMatchingArea';
import PhotoMatchingElementsArea from './components/PhotoMatchingElementsArea/PhotoMatchingElementsArea';
import { SetCollectionStatusFunction } from '@interfaces/collection.interface';
import { getPresentationId } from '@feature/presentationId.slice';
import Tutorial from '@components/common/Tutorial/Tutorial';
import ai from '@assets/images/ai.svg';
import TrgToolButton from '@components/TrgEdit/edit-tools/TrgToolButton';
import Button, { ButtonState } from '@components/common/button/Button';

const PhotoMatching = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [files, setFiles] = useState<IPhotoFile[]>([]);
    const [elements, setElements] = useState<ICollectionElement[]>();
    const [active_element, setActiveElement] = useState<ICollectionElement>();
    const [name, setName] = useState('');
    const [isOpenStorageModal, setIsOpenStorageModal] = useState(false);
    const [selected_photo, setPhotoSelected] = useState<IPhotoFile>();
    const directories = useRef<string[]>([]);
    const [marked_templates, setMarkedTemplates] = useState<string[]>([]);
    const [selected_template, setTemplateSelected] =
        useState<ICollectionElement>();
    const {
        parentId: parent_id,
        collectionId: collection_id,
        patientId: patient_id,
    } = useParams<{
        parentId: string;
        collectionId: string;
        patientId: string;
    }>();

    const selectedPatientStatus = useAppSelector(selectSelectedPatientStatus);
    // TODO: Удалить хранение презентации в редаксе, если появится поле presentationId у коллекции
    const presentationId = useAppSelector(getPresentationId);

    const onSuccessUpload = useCallback(() => {
        loadCollection().then(() =>
            loadFiles(directories.current).catch((error) =>
                console.error(error)
            )
        );
    }, [collection_id]);

    const onPhotoSelected = useCallback((file?: IPhotoFile) => {
        setPhotoSelected(file);
    }, []);

    const loadFiles = async (directories: Array<string>) => {
        const list: IPhotoFile[] = [];
        const list_of_files = await Promise.all(
            directories.map(async (folder_id) => {
                const {
                    data: { children },
                } = await PhotoArchiveApis.getFolder(folder_id);
                return (
                    children.filter(({ photo }) => !!photo) as Array<
                        Required<ICollectionElement>
                    >
                ).map(({ photo }) => ({
                    ...photo,
                    url: photo.full,
                }));
            })
        );

        list_of_files.forEach((item) => list.push(...item));

        setFiles(
            list.sort((a: IPhotoFile, b: IPhotoFile) =>
                ('' + a.name).localeCompare(b.name)
            )
        );
    };

    const onFolderSelect = (id: string) => {
        if (parent_id && collection_id && !directories.current.includes(id)) {
            PhotoCollectionApis.connectFolder(
                parent_id,
                collection_id,
                id
            ).catch(console.error);
            PhotoArchiveApis.getFolder(id).then((res) => {
                const folderFiles = [] as IPhotoFile[];
                res.data.children.map((el) => {
                    if (el.photo)
                        folderFiles.push({
                            ...el.photo,
                            url: el.photo.full,
                        });
                });
                setFiles((files) => [...files, ...folderFiles]);
            });
            directories.current.push(id);
        } else {
            toast.error('Данная папка уже добавлена');
        }
    };

    const markTemplate = (id: string) => {
        if (marked_templates.includes(id)) {
            setMarkedTemplates(marked_templates.filter((el) => el !== id));
        } else {
            setMarkedTemplates([...marked_templates, id]);
        }
    };

    const unlinkMarkedPhotos = async () => {
        marked_templates.map(async (id) => {
            await PhotoCollectionApis.unlinkPhoto(collection_id, id);
        });
        setElements(
            elements?.map((el) => {
                return marked_templates.includes(el.id)
                    ? {
                          id: el.id,
                          key: el.key,
                          name: el.name,
                          preview: el.preview,
                          previewURL: el.previewURL,
                          description: el.description,
                          presentations: el.presentations,
                      }
                    : el;
            })
        );
        setMarkedTemplates([]);
        setTemplateSelected(undefined);
    };

    async function loadCollection() {
        try {
            const { data: collectionData } =
                await PhotoCollectionApis.getCollectionData(
                    parent_id,
                    collection_id,
                    patient_id,
                    presentationId
                );
            setElements(collectionData.elements);
            setName(collectionData.title);

            setActiveElement(collectionData.elements[0]);

            if (collectionData.directories.length) {
                directories.current = collectionData.directories;
                await loadFiles(collectionData.directories);
            } else {
                directories.current = [];
                setFiles([]);
            }

            const statusData = await photoCollectionAPIs.getOne(
                parent_id,
                presentationId
            );
            dispatch(
                setPatientCollectionStatus(
                    createStatus(statusData.data.children, statusData.data.key)
                )
            );
        } catch (error: unknown) {
            if (axios.isAxiosError(error))
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            console.error(error);
        }
    }

    useEffect(() => {
        if (selected_photo && selected_template) {
            setElements(
                elements?.map((element: ICollectionElement) =>
                    element.id == selected_template.id
                        ? Object.assign(element, {
                              photo: {
                                  id: 'string',
                                  key: 'string',
                                  rotation: 0,
                                  scale: 0,
                                  timeCreated: 0,
                                  name: 'string',
                                  preview: selected_photo.url,
                                  full: 'string',
                              },
                          })
                        : element
                )
            );

            PhotoCollectionApis.updateCollectionPhoto(
                selected_template.id,
                selected_photo.id,
                collection_id
            ).catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                }
            });

            onPhotoSelected(undefined);
            setTemplateSelected(undefined);

            const currIndex =
                elements?.findIndex((e) => e.id == selected_template.id) ?? 0;

            if (elements) {
                setTemplateSelected(elements[currIndex + 1]);
                setActiveElement(elements[currIndex + 1]);
            }
        }
    }, [selected_photo, selected_template]);

    const openPresentation = () => {
        if (presentationId !== null) {
            history.push(ROUTES.PRESENTATIONS.$(presentationId).END);
        } else {
            history.push(
                ROUTES.PATIENTS.$(patient_id).STORE.FROM_COLLECTION.$(parent_id)
                    .END
            );
        }
    };

    const page_title = `Заполнение ${collectionTitles[name]}`;

    const onCollectionChange = useCallback(
        (collection_id: string) => {
            setPhotoSelected(undefined);
            setTemplateSelected(undefined);
            history.replace(
                ROUTES.PATIENTS.$(patient_id)
                    .PHOTOS.$(parent_id)
                    .FILL.$(collection_id).END
            );
        },
        [collection_id]
    );
    const setCollectionStatus: SetCollectionStatusFunction = (status) => {
        dispatch(
            updateCollectionStatusById({ id: collection_id, newStatus: status })
        );
    };

    useEffect(() => {
        loadCollection().catch((error) => console.error(error));
    }, [collection_id]);
    return (
        <div className={style.Root}>
            <div className={style.TitleContainer}>
                <div
                    className="user-info__header-back"
                    onClick={() => history.goBack()}
                ></div>

                <p className={style.Title}>{page_title}</p>
                <div className="store__free-space"></div>
                <div className={style.Button__container}>
                    <Tutorial tutorial={'collection'} />
                    <Button
                        onClick={openPresentation}
                        state={ButtonState.ACTIVE}
                        title={
                            presentationId
                                ? 'Собрать презентацию'
                                : 'Открыть презентации'
                        }
                    />
                </div>
            </div>
            <StorageModal
                isOpen={isOpenStorageModal}
                closeModal={() => setIsOpenStorageModal(false)}
                patientId={patient_id}
                onSelect={onFolderSelect}
            />
            <LoadingIndicator isLoading={false} />
            <div className={style.Container}>
                <StatusInfo
                    name={name}
                    statuses={selectedPatientStatus}
                    id={collection_id}
                    onCollectionChange={onCollectionChange}
                />
                <ElementInfo element={active_element} />
                <div className={style.BrowserContainer}>
                    <div className={style.TitleWrapper}>
                        <h3 className={style.SectionTitle}>
                            Загруженные файлы
                            <TrgToolButton
                                icon={ai}
                                title={'Расставить'}
                                tip={'Скоро - AI заполнение'}
                                isEnabled={false}
                                isActive={false}
                                onClick={function (): void {
                                    throw new Error(
                                        'Function not implemented.'
                                    );
                                }}
                            />
                        </h3>

                        <div
                            className={style.FolderPicker}
                            onClick={() => setIsOpenStorageModal(true)}
                        >
                            Выбрать папку
                        </div>
                    </div>
                    <div className={style.FileContainer}>
                        <PhotoMatchingArea
                            componentState={{ patientId: patient_id }}
                            onSuccessUpload={onSuccessUpload}
                            collectionId={collection_id}
                            photoSelected={selected_photo}
                            onPhotoSelected={onPhotoSelected}
                            files={files}
                        />
                    </div>
                </div>
                <div className={style.CollectionContainer}>
                    <div className={style.TitleWrapper}>
                        <h3 className={style.SectionTitle}>Шаблоны</h3>
                        <div
                            className={`${style.DeleteButton} ${
                                marked_templates.length === 0
                                    ? style.DeleteButton__disabled
                                    : ''
                            }`}
                            onClick={unlinkMarkedPhotos}
                        >
                            Удалить
                        </div>
                    </div>
                    <div className={style.FileContainer}>
                        <PhotoMatchingElementsArea
                            markedTemplates={marked_templates}
                            onMark={markTemplate}
                            onTemplateSelected={setTemplateSelected}
                            setActiveElement={setActiveElement}
                            templateSelected={selected_template}
                            elements={elements}
                            setCollectionStatus={setCollectionStatus}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhotoMatching;
