import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ProgressBar from '@components/common/ProgressBar/ProgressBar';
import { uploadPhoto } from '@utils/photo';
import { DragProps } from '@components/photo/body/browser/types';
import style from './PhotoFileUploader.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomToastError from '@components/UI/CustomToastError/CustomToastError';
import { ROUTES } from '@constants/routes';
import { useHistory } from 'react-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PhotoFileUploader = (props: DragProps) => {
    let dragCounter = 0;
    const history = useHistory();

    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState<DataTransferItem[]>([]);
    const [percent, setPercent] = useState(50);
    const [loading, setLoading] = useState(false);
    const [onUploadPhotosCount, setOnUploadPhotosCount] = useState(0);
    const [uploadedPhotosCount, setUploadedPhotosCount] = useState(0);
    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCounter++;

        const files: DataTransferItem[] = [];
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
            const file = event.dataTransfer.items[i];

            if (
                file &&
                (file.type == 'image/png' ||
                    file.type == 'image/jpg' ||
                    file.type == 'image/jpeg')
            )
                files.push(file);
        }

        if (event.dataTransfer.items && files.length > 0) {
            setDragging(true);
            setFiles(files);
        }
    };

    const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCounter--;
        if (dragCounter === 0) {
            setDragging(false);
        }
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setDragging(false);

        const files: globalThis.File[] = [];
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            const file = event.dataTransfer.files.item(i);

            if (
                file &&
                (file.type == 'image/png' ||
                    file.type == 'image/jpg' ||
                    file.type == 'image/jpeg')
            ) {
                files.push(file);
            }
        }

        if (event.dataTransfer.files && files.length > 0) {
            uploadImages(files);
        }
    };

    const uploadImages = async (files: globalThis.File[]) => {
        setLoading(true);
        setUploadedPhotosCount(0);
        dragCounter = 0;

        setOnUploadPhotosCount(files.length);
        let numberOfSucceededTasks = 0;

        for (const [, file] of files.entries()) {
            try {
                setPercent(0);
                await uploadPhoto(
                    props.identifier,
                    props.collectionId,
                    file,
                    (progressEvent: any) => {
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );

                        setPercent(progress);
                    }
                );
                numberOfSucceededTasks += 1;
                setUploadedPhotosCount((prevState) => prevState + 1);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 405) {
                        toast.error(
                            <CustomToastError
                                text={
                                    'Достигнут лимит по хранилищу, приобретите подписку'
                                }
                                buttonText={'Тарифы'}
                                onClick={() => history.push(ROUTES.PROFILE.END)}
                            />
                        );
                    }
                } else {
                    console.error(error);
                }
                setLoading(false);
                return;
            }

            if (numberOfSucceededTasks === files.length) {
                props.onSuccessUpload();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dragCounter = 0;
    }, []);

    const onFileInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files && files?.length) {
                uploadImages(Array.from(files));
            }
        },
        [props.collectionId]
    );

    return (
        <div className={dragging ? style.ContainerDragging : style.Container}>
            <div
                className={style.DDContainer}
                onDragOver={handleDrag.bind(this)}
                onDragEnter={handleDragIn.bind(this)}
                onDragLeave={handleDragOut.bind(this)}
                onDrop={handleDrop.bind(this)}
            >
                {!loading ? (
                    <div className={style.DDButton}>
                        <p
                            className={
                                dragging ? style.DDLabel : style.DDLabelDragging
                            }
                        >
                            {dragging && files ? (
                                `Загрузится изображений: ${files.length}`
                            ) : (
                                <label style={{ cursor: 'pointer' }}>
                                    <input
                                        type="file"
                                        title="Добавить"
                                        style={{ display: 'none' }}
                                        accept={'image/png, image/jpeg'}
                                        multiple={true}
                                        onChange={onFileInputChange}
                                    />
                                    <h4> Выберите фотографии</h4>
                                    <p>или перетащите их сюда</p>
                                </label>
                            )}
                        </p>
                    </div>
                ) : (
                    <div className={style.DDLoading}>
                        <p className={style.DDLabel}>Загрузка</p>
                        {onUploadPhotosCount !== 1 && (
                            <p className={style.DDLoading__countProgress}>
                                {uploadedPhotosCount} из {onUploadPhotosCount}
                            </p>
                        )}
                        <p className={style.DDLoading__textProgress}>
                            {percent}%
                        </p>
                        <ProgressBar bgcolor="#2A8BF2" completed={percent} />
                    </div>
                )}
            </div>
        </div>
    );
};
