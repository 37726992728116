import React from 'react';
import ReactDOM from 'react-dom/client';
import '@styles/reset.css';
import './index.css';
import App from './App';

import { AuthProvider } from '@contexts/AuthContext';
import { Provider } from 'react-redux';
import { store } from '@store/index';
import { BrowserRouter } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
