import React, { useCallback, useEffect, useState } from 'react';
import { updatePageText } from '@utils/presentation';
import '@pages/PresentationEdit/components/PresentationEditRectItem/PresentationEditRectItem.scss';
import { useDebounce } from '@hooks/useDebounce';
import { IText, ITransform } from '@interfaces/presentation.interface';
import DOMPurify from 'dompurify';

type PresentationEditTextItemProps = {
    id: string;
    text: IText;
    textIndex: number;
    updateText: (text: string, textIndex: number) => void;
    selectedElementId: string;
    setSelectedElementId: (id: string) => void;
};

const PresentationEditTextItem = ({
    id,
    text,
    selectedElementId,
    setSelectedElementId,
    updateText,
    textIndex,
}: PresentationEditTextItemProps): JSX.Element => {
    const [inputText, setInputText] = useState('');
    const percentOriginalHeight = text.box.height / 1080;
    const percentOriginalWidth = text.box.width / 1920;
    const percentHeight =
        document.getElementsByClassName('presentation_page')[0].clientHeight;
    const percentWidth =
        document.getElementsByClassName('presentation_page')[0].clientWidth;

    useEffect(() => {
        setInputText(text.editing.value ?? text.text);
    }, [text.id]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== 'Enter') return;

        e.preventDefault();
        const textarea = e.target as HTMLTextAreaElement;
        const { selectionStart, selectionEnd } = textarea;
        const beforeCursor = inputText.slice(0, selectionStart);
        const afterCursor = inputText.slice(selectionEnd);
        const updatedText = `${beforeCursor}\n• ${afterCursor}`;

        setInputText(updatedText);
        textarea.setSelectionRange(selectionStart + 3, selectionStart + 3);
        textarea.focus();
    };

    const parentBoxX = text.box.x / 1920;
    const parentBoxY = text.box.y / 1080;

    const size = text.size / 1080;

    const sendTextChange = useCallback(
        useDebounce(
            (
                id: string,
                transform: { editing: ITransform & { value: string } }
            ) => {
                if (id) {
                    updatePageText(id, transform);
                    updateText(transform.editing.value, textIndex);
                }
            },
            180
        ),
        [updateText]
    );

    const { r = 0, g = 0, b = 0, a = 1 } = text.style.color ?? {};

    return (
        <>
            {text.editable && (
                <textarea
                    id={id}
                    key={id}
                    value={inputText}
                    placeholder={'Введите свой текст'}
                    style={{
                        resize: 'none',
                        position: 'absolute',
                        marginLeft: percentWidth * parentBoxX,
                        marginTop: percentHeight * parentBoxY,
                        width: percentWidth * percentOriginalWidth - 4,
                        height: percentOriginalHeight * percentHeight - 4,
                        textAlign: text.align ?? 'left',
                        color: `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`,
                        fontSize: percentHeight * size,
                        fontFamily: `${DOMPurify.sanitize(
                            text.style.fontFamily ?? 'Roboto'
                        )}, sans-serif`,
                        fontWeight: text.weight,
                        backgroundImage: 'none',
                    }}
                    className={
                        selectedElementId == text.id
                            ? 'text-component_selected'
                            : 'text-component'
                    }
                    onClick={() => setSelectedElementId(text.id)}
                    onChange={(e) => {
                        setInputText(e.target.value);
                        sendTextChange(text.id, {
                            editing: { value: e.target.value },
                        });
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            e.stopPropagation();
                        } else if (text.formatting === 'list') {
                            handleKeyDown(e);
                        }
                    }}
                />
            )}
            {!text.editable && (
                <p
                    id={id}
                    key={id}
                    style={{
                        position: 'absolute',
                        marginLeft: percentWidth * parentBoxX,
                        marginTop: percentHeight * parentBoxY,
                        textOverflow: 'ellipsis',
                        width: percentWidth * percentOriginalWidth + 15,
                        height: percentOriginalHeight * percentHeight,
                        color: `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`,
                        textAlign: text.align ?? 'center',
                        fontSize: percentHeight * size,
                        fontFamily: `${DOMPurify.sanitize(
                            text.style.fontFamily ?? 'Roboto'
                        )}, sans-serif`,
                        fontWeight: text.weight,
                    }}
                >
                    {text.text}
                </p>
            )}
        </>
    );
};

export default PresentationEditTextItem;
