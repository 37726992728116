import React, { useEffect, useRef, useState } from 'react';
import '@pages/PresentationEdit/PresentationEditPage.scss';
import { RectComponent } from '@pages/PresentationEdit/components/RectComponent/RectComponent';
import PresentationEditTextItem from '@pages/PresentationEdit/components/PresentationEditTextItem/PresentationEditTextItem';
import PresentationEditToolbar from '@pages/PresentationEdit/components/PresentationEditToolbar/PresentationEditToolbar';

import EditingLayer from '@pages/PresentationEdit/components/EditingLayer/EditingLayer';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectSelectedPresentationTool } from '@feature/presentationTools.slice';
import {
    setSelectedFigure,
    setShapes,
} from '@feature/presentationEditFigures.slice';
import {
    IArrow,
    ILine,
    IRectangle,
    PresentationToolsEnum,
} from '@interfaces/presentationEditing.interface';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { IPage, ITransform } from '@interfaces/presentation.interface';

type PresentationEditPageProps = {
    page: IPage | null;
    updatePageFigures: (
        pageId: string,
        figures: {
            lines?: ILine[];
            rectangles?: IRectangle[];
            arrows?: IArrow[];
            curves?: ILine[];
            dotted_lines?: ILine[];
        }
    ) => void;
    setStageSize: React.Dispatch<
        React.SetStateAction<{ width: number; height: number }>
    >;
    stageSize: { width: number; height: number };
};

const PresentationEditPage = ({
    page,
    updatePageFigures,
    setStageSize,
    stageSize,
}: PresentationEditPageProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const [selectedElementId, setSelectedElementId] = useState<string>('');
    const selectedTool = useAppSelector(selectSelectedPresentationTool);

    const presentationPageRef = useRef<HTMLDivElement>(null);

    // resize Editing Layer
    useEffect(() => {
        if (presentationPageRef.current) {
            const updateStageSize = () => {
                const width = presentationPageRef.current?.clientWidth || 0;
                const height = presentationPageRef.current?.clientHeight || 0;
                setStageSize({ width, height });
            };

            updateStageSize();

            const resizeObserver = new ResizeObserver(() => {
                updateStageSize();
            });

            resizeObserver.observe(presentationPageRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    // Update the rect transform
    const update = (transform: ITransform, _rectIndex: number): void => {
        if (page) page.images[_rectIndex].editing = transform;
    };

    const selectElement = (id: string) => {
        setSelectedElementId(id);
    };

    useEffect(() => {
        if (selectedTool === PresentationToolsEnum.CURSOR) {
            dispatch(setSelectedFigure(null));
        } else {
            setSelectedElementId('');
        }
    }, [selectedTool]);

    useEffect(() => {
        if (page) {
            dispatch(setShapes(page.shapes));
            dispatch(setSelectedFigure(null));
        }
    }, [page]);

    return (
        <div className="presentation_page_root">
            <PresentationEditToolbar pageId={page?.id} />
            <div className="presentation_page" ref={presentationPageRef}>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        minBlockSize: '100%',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <div>
                        <div
                            style={{
                                width: '-webkit-fill-available',
                                height: '-webkit-fill-available',
                                position: 'absolute',
                            }}
                            onMouseDown={() => {
                                setSelectedElementId('');
                            }}
                        />
                    </div>

                    {(page?.images || []).map((rect, index) => (
                        <RectComponent
                            rect={rect}
                            rectIndex={index}
                            key={index}
                            update={update}
                            selectElement={selectElement}
                            selectedElementId={selectedElementId}
                        />
                    ))}
                    {(page?.texts || []).map((text, index) => (
                        <PresentationEditTextItem
                            id={text.id}
                            selectedElementId={selectedElementId}
                            setSelectedElementId={setSelectedElementId}
                            text={text}
                            textIndex={index}
                            updateText={(value, textIndex) => {
                                if (page?.texts && page?.texts[textIndex]) {
                                    page.texts[textIndex].editing.value = value;
                                }
                            }}
                            key={index}
                        />
                    ))}
                    <div className={'editingLayerContainer'}>
                        <EditingLayer
                            stageSize={stageSize}
                            pageId={page?.id}
                            updatePageFigures={updatePageFigures}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PresentationEditPage;
