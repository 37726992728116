import React, { useState } from 'react';
import styles from './Palette.module.scss';
import { classes } from '@utils/classNames';
import { paletteColors } from '@constants/paletteColors';

const Palette = ({
    color,
    setColor,
}: {
    color: string;
    setColor: (color: string) => void;
}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    return (
        <div
            className={classes(styles.palette, {
                [styles.palette_opened]: isOpened,
            })}
        >
            <div
                className={styles.palette__frontBlock}
                onClick={() => setIsOpened((prevState) => !prevState)}
            >
                <div
                    className={styles.palette__colorBlock}
                    style={{ backgroundColor: color }}
                ></div>
                <div className={styles.palette__textBlock}>{color}</div>
            </div>
            <div
                className={styles.palette__modal}
                style={{ height: isOpened ? '104px' : '0' }}
            >
                <div className={styles.palette__modalColors}>
                    {paletteColors.map((color) => (
                        <div
                            key={color}
                            style={{ backgroundColor: color }}
                            className={styles.palette__modalColorsItem}
                            onClick={() => setColor(color)}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Palette;
