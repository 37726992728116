import React, { useEffect, useState } from 'react';
import { ICollectionElement } from '@interfaces/collection-element.interface';

import { IPatient } from '@interfaces/patient.interface';
import styles from '../PhotoTemplatesPageTable.module.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PhotoCollection = ({
    elements,
}: {
    elements: ICollectionElement[];
}) => {
    return (
        <>
            <div className={styles.Collection}>
                <div className={styles.PatternContainer}>
                    <div className={styles.Patterns}>
                        {elements.map(
                            (item: ICollectionElement, index: number) => {
                                return (
                                    <Photo
                                        collectionElement={item}
                                        key={index}
                                    />
                                );
                            }
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const Photo = ({
    collectionElement,
}: {
    collectionElement: ICollectionElement;
}) => {
    return (
        <div className="image_pattern_container_main">
            <div className={styles.Pattern}>
                <img
                    alt={'loaded image'}
                    style={{
                        width: collectionElement.photo ? '100%' : '50%',
                        height: collectionElement.photo ? '100%' : '50%',
                        borderRadius: 6,
                        objectFit: collectionElement.photo
                            ? 'cover'
                            : 'contain',
                        opacity: 0,
                    }}
                    src={
                        collectionElement.photo?.preview ??
                        collectionElement.previewURL
                    }
                    id={collectionElement?.id + 'preview'}
                    onLoad={(e) =>
                        ((e.target as HTMLDivElement).style.opacity = '1')
                    }
                    onError={(e) =>
                        ((e.target as HTMLDivElement).style.opacity = '0')
                    }
                />
            </div>
        </div>
    );
};
