import React from 'react';
import styles from './PhotoTemplatesPageTable.module.scss';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { PhotoTemplatesPageTableColumns } from './PhotoTemplatesPageTableColumns';
import { makeStyles } from '@material-ui/core';
import { ICollection } from '@interfaces/collection.interface';

const row =
    '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell';
const rowHover = '&.MuiDataGrid-root .MuiDataGrid-row:hover';
const cell =
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus';
const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            overflow: 'inherit',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
            overflow: 'visible',
        },
        [cell]: {
            overflow: 'visible',
        },
        [row]: {
            overflow: 'visible',
            outline: 'none',
        },
        [rowHover]: {
            backgroundColor: '#b2b2b214',
        },
        '& .MuiDataGrid-columnHeaders': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
            color: '#000000',
        },
    },
});

export const PhotoTemplatesPageTable = ({
    collections,
    isLoading = false,
}: {
    collections: ICollection[];
    isLoading?: boolean;
}): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={styles.Container}>
            <DataGrid
                className={classes.root}
                columns={PhotoTemplatesPageTableColumns()}
                rows={[...collections].sort(
                    (a, b) => b.updated_at - a.updated_at
                )}
                disableColumnMenu={true}
                loading={isLoading}
                hideFooterSelectedRowCount={true}
                getRowHeight={() => 'auto'}
                disableSelectionOnClick={true}
                getRowId={(row) => row.id}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
};

export default PhotoTemplatesPageTable;
