import React, { useEffect, useState } from 'react';

import styles from './PhotoTemplatesPage.module.scss';
import {
    photoCollectionAPIs,
    PhotoCollectionApis,
} from '@APIs/photo-collection.apis';
import {
    ICollection,
    ICollectionGroup,
} from '@interfaces/collection.interface';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectSelectedPatient,
    setPatientCollectionStatus,
} from '@store/feature/patient.slice';
import { Alert } from '@components/common/alert/Alert';
import PhotoTemplatesPageTable from './PhotoTemplatesPageTable';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useParams } from 'react-router';
import { createStatus } from '@utils/photo-collections';

const PhotoTemplatesPage = (): JSX.Element => {
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const dispatch = useAppDispatch();
    const [collections, setCollections] = useState<ICollection[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const { collectionId } = useParams<{ collectionId: string }>();
    const [collectionGroup, setCollectionGroup] = useState<ICollectionGroup>();

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        if (selectedPatient) {
            setIsLoading(true);
            photoCollectionAPIs.getOne(collectionId).then(({ data }) => {
                setCollectionGroup(data);
                setCollections(data.children);
                dispatch(
                    setPatientCollectionStatus(
                        createStatus(data.children, data.key)
                    )
                );
                setIsLoading(false);
            });
        }
    }, [selectedPatient, isMounted]);

    return (
        <div className={styles.Container}>
            <Alert
                title="Фотоколлекции"
                description="В фотоколлекции необходимо загрузить и рассортировать фотографии пациента, данные КЛКТ и другую информацию, которая далее будет использована для автоматического создания презентаций."
                id="patient_photo"
            />
            <h2 className={styles.Header}>
                Фотоколлекция {collectionGroup?.title}
            </h2>
            <PhotoTemplatesPageTable
                collections={collections}
                isLoading={isLoading}
            />
        </div>
    );
};

export default PhotoTemplatesPage;
