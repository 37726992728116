import React, { useCallback, useEffect, useState } from 'react';
import Konva from 'konva';
import { classes } from '@utils/classNames';
import styles from './EditingLayer.module.scss';
import {
    IArrow,
    ILine,
    IRectangle,
    PresentationFiguresEnum,
    PresentationToolsEnum,
} from '@interfaces/presentationEditing.interface';
import { Arrow, Layer, Line, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectPresentationToolColor,
    selectSelectedPresentationTool,
} from '@feature/presentationTools.slice';
import {
    addArrow,
    addDottedLine,
    addLine,
    addLineFigure,
    addRectangle,
    selectArrows,
    selectCurves,
    selectDottedLines,
    selectLines,
    selectRectangles,
    selectSelectedFigure,
    setSelectedFigure,
    updateArrow,
    updateDottedLine,
    updateLine,
    updateRectangle,
} from '@feature/presentationEditFigures.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import pencilCursor from '@images/presentationPencil.svg';
import RectangleComponent from '@pages/PresentationEdit/components/EditingLayer/RectangleComponent/RectangleComponent';
import ArrowComponent from '@pages/PresentationEdit/components/EditingLayer/ArrowComponent/ArrowComponent';
import LineComponent from '@pages/PresentationEdit/components/EditingLayer/LineComponent/LineComponent';
import {
    INITIAL_RECTANGLE_HEIGHT,
    INITIAL_RECTANGLE_WIDTH,
} from '@constants/presentationEditConstants';
import { presentationAPIs } from '@APIs/presentation.apis';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import useButtonListeners from '@hooks/useButtonListeners';

const EditingLayer = ({
    stageSize,
    pageId,
    updatePageFigures,
}: {
    stageSize: {
        width: number;
        height: number;
    };
    pageId: string | undefined;
    updatePageFigures: (
        pageId: string,
        figures: {
            lines?: ILine[];
            rectangles?: IRectangle[];
            arrows?: IArrow[];
            curves?: ILine[];
            dotted_lines?: ILine[];
        }
    ) => void;
}) => {
    const dispatch = useAppDispatch();
    const selectedTool = useAppSelector(selectSelectedPresentationTool);
    const selectedFigure = useAppSelector(selectSelectedFigure);
    const toolColor = useAppSelector(selectPresentationToolColor);
    const curves = useAppSelector(selectCurves);
    const rectangles = useAppSelector(selectRectangles);
    const arrows = useAppSelector(selectArrows);
    const lines = useAppSelector(selectLines);
    const dottedLines = useAppSelector(selectDottedLines);

    const [isFirstClick, setIsFirstClick] = useState(true);

    const [currentCurve, setCurrentCurve] = useState<Omit<ILine, 'id'> | null>(
        null
    );
    const [currentArrow, setCurrentArrow] = useState<Omit<IArrow, 'id'> | null>(
        null
    );
    const [currentLine, setCurrentLine] = useState<Omit<ILine, 'id'> | null>(
        null
    );
    const [currentDottedLine, setCurrentDottedLine] = useState<Omit<
        ILine,
        'id'
    > | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    // хэндлер события нажатия мыши
    const handleMouseDown = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (selectedTool === PresentationToolsEnum.CURSOR) return;

            const pos = e?.target.getStage()?.getPointerPosition();
            if (!pos) return;

            switch (selectedTool) {
                case PresentationToolsEnum.PAINT:
                    handleCurveMouseDown(e, pos);
                    break;
                case PresentationToolsEnum.RECTANGLE:
                    handleRectangleMouseDown(e, pos);
                    break;
                case PresentationToolsEnum.ARROW:
                    handleArrowMouseDown(e, pos);
                    break;
                case PresentationToolsEnum.LINE:
                    handleLineMouseDown(e, pos);
                    break;
                case PresentationToolsEnum.DOTTEDLINE:
                    handleDottedLineMouseDown(e, pos);
                    break;
                default:
                    break;
            }
        },
        [
            selectedTool,
            toolColor,
            isFirstClick,
            currentArrow,
            currentLine,
            currentDottedLine,
            pageId,
        ]
    );

    const handleCurveMouseDown = (
        e: KonvaEventObject<MouseEvent>,
        pos: { x: number; y: number }
    ) => {
        const clickedOnCurve = e.target instanceof Konva.Line<any>;
        if (!clickedOnCurve) {
            setCurrentCurve({
                type: PresentationFiguresEnum.CURVE,
                color: toolColor,
                width: 3,
                points: [pos.x, pos.y],
            });
        }
    };

    const handleRectangleMouseDown = (
        e: KonvaEventObject<MouseEvent>,
        pos: { x: number; y: number }
    ) => {
        const clickedOnRectangle = e.target instanceof Konva.Rect;
        if (!clickedOnRectangle && pageId) {
            setIsLoading(true);
            const newRectangle = {
                type: PresentationFiguresEnum.RECTANGLE,
                x: pos.x,
                y: pos.y,
                width: INITIAL_RECTANGLE_WIDTH,
                height: INITIAL_RECTANGLE_HEIGHT,
                stroke: toolColor,
                strokeWidth: 3,
                rotation: 0,
            };
            presentationAPIs
                .appendRectangle(
                    pageId,
                    stageSize.width,
                    stageSize.height,
                    newRectangle
                )
                .then((res) => {
                    dispatch(addRectangle(res.data));
                    dispatch(
                        setSelectedFigure({
                            id: res.data.id,
                            type: res.data.type,
                        })
                    );
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleArrowMouseDown = (
        e: KonvaEventObject<MouseEvent>,
        pos: { x: number; y: number }
    ) => {
        const clickedOnArrow =
            e.target instanceof Konva.Arrow || e.target instanceof Konva.Circle;

        if (isFirstClick && !clickedOnArrow) {
            setCurrentArrow({
                startPoint: { x: pos.x, y: pos.y },
                endPoint: { x: pos.x, y: pos.y },
                color: toolColor,
                width: 3,
                type: PresentationFiguresEnum.ARROW,
            });
            setIsFirstClick(false);
        } else if (!isFirstClick && currentArrow) {
            setCurrentArrow((prev) =>
                prev ? { ...prev, endPoint: { x: pos.x, y: pos.y } } : null
            );

            if (pageId) {
                const updatedArrow = {
                    ...currentArrow,
                    endPoint: { x: pos.x, y: pos.y },
                };

                presentationAPIs
                    .appendArrow(
                        pageId,
                        stageSize.width,
                        stageSize.height,
                        updatedArrow
                    )
                    .then((res) => {
                        dispatch(addArrow(res.data));
                        dispatch(
                            setSelectedFigure({
                                type: PresentationFiguresEnum.ARROW,
                                id: res.data.id,
                            })
                        );
                    })
                    .finally(() => {
                        setCurrentArrow(null);
                        setIsFirstClick(true);
                    });
            }
        } else {
            setCurrentArrow(null);
            setIsFirstClick(true);
        }
    };

    const handleLineMouseDown = (
        e: KonvaEventObject<MouseEvent>,
        pos: { x: number; y: number }
    ) => {
        const clickedOnLine =
            e.target instanceof Konva.Line<any> ||
            e.target instanceof Konva.Circle;
        if (isFirstClick && !clickedOnLine) {
            setCurrentLine({
                color: toolColor,
                width: 3,
                points: [pos.x, pos.y, pos.x, pos.y],
                type: PresentationFiguresEnum.LINE,
            });
            setIsFirstClick(false);
        } else if (currentLine && pageId) {
            presentationAPIs
                .appendLine(
                    pageId,
                    stageSize.width,
                    stageSize.height,
                    currentLine
                )
                .then((res) => {
                    dispatch(addLine(res.data));
                    dispatch(
                        setSelectedFigure({
                            id: res.data.id,
                            type: PresentationFiguresEnum.LINE,
                        })
                    );
                })
                .finally(() => {
                    setCurrentLine(null);
                    setIsFirstClick(true);
                });
        } else {
            setCurrentLine(null);
            setIsFirstClick(true);
        }
    };

    const handleDottedLineMouseDown = (
        e: KonvaEventObject<MouseEvent>,
        pos: { x: number; y: number }
    ) => {
        const clickedOnDottedLine =
            e.target instanceof Konva.Line<any> ||
            e.target instanceof Konva.Circle;
        if (isFirstClick && !clickedOnDottedLine) {
            setCurrentDottedLine({
                color: toolColor,
                width: 3,
                points: [pos.x, pos.y, pos.x, pos.y],
                type: PresentationFiguresEnum.DOTTEDLINE,
            });
            setIsFirstClick(false);
        } else if (currentDottedLine && pageId) {
            presentationAPIs
                .appendDottedLine(
                    pageId,
                    stageSize.width,
                    stageSize.height,
                    currentDottedLine
                )
                .then((res) => {
                    dispatch(addDottedLine(res.data));
                    dispatch(
                        setSelectedFigure({
                            id: res.data.id,
                            type: PresentationFiguresEnum.DOTTEDLINE,
                        })
                    );
                })
                .finally(() => {
                    setCurrentDottedLine(null);
                    setIsFirstClick(true);
                });
        } else {
            setCurrentDottedLine(null);
            setIsFirstClick(true);
        }
    };

    // хэндлер события перемещения мыши
    const handleMouseMove = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (
                isLoading ||
                (!currentCurve &&
                    !currentArrow &&
                    !currentLine &&
                    !currentDottedLine)
            )
                return;

            switch (selectedTool) {
                case PresentationToolsEnum.PAINT:
                    handleCurveMouseMove(e);
                    break;
                case PresentationToolsEnum.ARROW:
                    handleArrowMouseMove(e);
                    break;
                case PresentationToolsEnum.LINE:
                    handleLineMouseMove(e);
                    break;
                case PresentationToolsEnum.DOTTEDLINE:
                    handleDottedLineMouseMove(e);
                    break;
                default:
                    break;
            }
        },
        [
            currentCurve,
            currentArrow,
            selectedTool,
            currentLine,
            currentDottedLine,
            isLoading,
        ]
    );

    const handleCurveMouseMove = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            const stage = e.target.getStage();
            const point = stage?.getPointerPosition();
            if (point && currentCurve) {
                setCurrentCurve((prevLine) => {
                    if (!prevLine) return prevLine;
                    return {
                        ...prevLine,
                        points: prevLine.points.concat([point.x, point.y]),
                    };
                });
            }
        },
        [currentCurve]
    );

    const handleArrowMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (currentArrow) {
            const stage = e.target.getStage();
            const point = stage?.getPointerPosition();
            if (point) {
                setCurrentArrow({
                    ...currentArrow,
                    endPoint: { x: point.x, y: point.y },
                });
            }
        }
    };

    const handleLineMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (currentLine) {
            const stage = e.target.getStage();
            const point = stage?.getPointerPosition();
            if (point) {
                setCurrentLine((prevLine) => {
                    if (prevLine) {
                        return {
                            ...prevLine,
                            points: [
                                ...prevLine.points.slice(0, 2),
                                point.x,
                                point.y,
                            ],
                        };
                    }
                    return null;
                });
            }
        }
    };

    const handleDottedLineMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (currentDottedLine) {
            const stage = e.target.getStage();
            const point = stage?.getPointerPosition();
            if (point) {
                setCurrentDottedLine((prevLine) => {
                    if (prevLine) {
                        return {
                            ...prevLine,
                            points: [
                                ...prevLine.points.slice(0, 2),
                                point.x,
                                point.y,
                            ],
                        };
                    }
                    return null;
                });
            }
        }
    };

    // хэндлер события отпускания мыши
    const handleMouseUp = useCallback(() => {
        if (
            selectedTool === PresentationToolsEnum.PAINT &&
            currentCurve &&
            currentCurve.points.length >= 4 &&
            pageId
        ) {
            setIsLoading(true);
            presentationAPIs
                .appendCurve(
                    pageId,
                    stageSize.width,
                    stageSize.height,
                    currentCurve
                )
                .then((res) => dispatch(addLineFigure(res.data)))
                .finally(() => {
                    setCurrentCurve(null);
                    setIsLoading(false);
                });
        } else {
            setCurrentCurve(null);
        }
    }, [currentCurve, pageId, stageSize, selectedTool]);

    // хэндлер события ухода мыши
    const handleMouseLeave = useCallback(() => {
        if (currentCurve && pageId && !isLoading) {
            setIsLoading(true);
            presentationAPIs
                .appendCurve(
                    pageId,
                    stageSize.width,
                    stageSize.height,
                    currentCurve
                )
                .then((res) => dispatch(addLineFigure(res.data)))
                .finally(() => {
                    setCurrentCurve(null);
                    setIsLoading(false);
                });
        }
    }, [currentCurve, pageId, stageSize, selectedTool, isLoading]);

    // хэндлер события изменения курсора на экране редактирования
    const handleMouseEnter = useCallback(
        (container: HTMLElement | null) => {
            if (!container) return;
            if (selectedTool === PresentationToolsEnum.PAINT) {
                container.style.cursor = `url(${pencilCursor}) 0 20, auto`;
            } else {
                container.style.cursor = 'default';
            }
        },
        [selectedTool]
    );

    // хэндлер события ухода курсора с линии
    const handleCurveLeave = useCallback((container: HTMLElement | null) => {
        if (!container) return;
        container.style.cursor = `url(${pencilCursor}) 0 20, auto`;
    }, []);
    // хэндлер события курсора на линии
    const handleCurveEnter = useCallback((container: HTMLElement | null) => {
        if (!container) return;
        container.style.cursor = 'pointer';
    }, []);

    useButtonListeners([
        {
            keys: ['Escape', 'Delete'],
            callback: () => {
                setCurrentArrow(null);
                setIsFirstClick(true);
            },
        },
    ]);

    useEffect(() => {
        if (pageId) {
            updatePageFigures(pageId, {
                arrows,
                lines,
                curves,
                rectangles,
                dotted_lines: dottedLines,
            });
        }
    }, [arrows, lines, curves, rectangles, dottedLines]);

    return (
        <div
            className={classes({
                [styles.pencilCursor]:
                    selectedTool === PresentationToolsEnum.PAINT,
            })}
            style={{
                pointerEvents:
                    selectedTool === PresentationToolsEnum.CURSOR || isLoading
                        ? 'none'
                        : 'auto',
                position: 'relative',
            }}
        >
            <Stage
                width={stageSize.width}
                height={stageSize.height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={(e) =>
                    handleMouseEnter(
                        e.target.getStage()?.container() as HTMLElement
                    )
                }
            >
                <Layer listening={selectedTool === PresentationToolsEnum.PAINT}>
                    {curves.map((curve) => (
                        <Line
                            onClick={() =>
                                dispatch(
                                    setSelectedFigure({
                                        id: curve.id,
                                        type: curve.type,
                                    })
                                )
                            }
                            onMouseEnter={(e) =>
                                handleCurveEnter(
                                    e.target
                                        .getStage()
                                        ?.container() as HTMLElement
                                )
                            }
                            onMouseLeave={(e) =>
                                handleCurveLeave(
                                    e.target
                                        .getStage()
                                        ?.container() as HTMLElement
                                )
                            }
                            key={curve.id}
                            points={curve.points}
                            stroke={curve.color}
                            strokeWidth={3}
                            dash={
                                curve.id === selectedFigure?.id
                                    ? [8, 5]
                                    : undefined
                            }
                            opacity={curve.id === selectedFigure?.id ? 0.5 : 1}
                        />
                    ))}
                    <Line
                        points={currentCurve?.points}
                        stroke={currentCurve?.color}
                        strokeWidth={3}
                    />
                </Layer>
                <Layer
                    listening={selectedTool === PresentationToolsEnum.RECTANGLE}
                >
                    {rectangles.map((rect) => (
                        <RectangleComponent
                            key={rect.id}
                            shapeProps={rect}
                            isSelected={rect.id === selectedFigure?.id}
                            onSelect={() => {
                                if (
                                    selectedTool ===
                                    PresentationToolsEnum.RECTANGLE
                                )
                                    dispatch(
                                        setSelectedFigure({
                                            id: rect.id,
                                            type: rect.type,
                                        })
                                    );
                            }}
                            onChange={(newAttrs) => {
                                if (pageId) {
                                    setIsLoading(true);
                                    presentationAPIs
                                        .updateRectangle(
                                            pageId,
                                            stageSize.width,
                                            stageSize.height,
                                            newAttrs
                                        )
                                        .then((res) =>
                                            dispatch(updateRectangle(res.data))
                                        )
                                        .finally(() => setIsLoading(false));
                                }
                            }}
                        />
                    ))}
                </Layer>
                <Layer listening={selectedTool === PresentationToolsEnum.ARROW}>
                    {arrows.map((arrow) => (
                        <ArrowComponent
                            key={arrow.id}
                            shapeProps={arrow}
                            isSelected={arrow.id === selectedFigure?.id}
                            onSelect={() => {
                                if (
                                    selectedTool === PresentationToolsEnum.ARROW
                                )
                                    dispatch(
                                        setSelectedFigure({
                                            id: arrow.id,
                                            type: arrow.type,
                                        })
                                    );
                            }}
                            onChange={(newAttrs) => {
                                if (pageId) {
                                    setIsLoading(true);
                                    presentationAPIs
                                        .updateArrow(
                                            pageId,
                                            stageSize.width,
                                            stageSize.height,
                                            newAttrs
                                        )
                                        .then((res) =>
                                            dispatch(updateArrow(res.data))
                                        )
                                        .finally(() => setIsLoading(false));
                                }
                            }}
                        />
                    ))}
                    {currentArrow && (
                        <Arrow
                            points={[
                                currentArrow.startPoint.x,
                                currentArrow.startPoint.y,
                                currentArrow.endPoint.x,
                                currentArrow.endPoint.y,
                            ]}
                            strokeWidth={currentArrow.width}
                            stroke={currentArrow.color}
                            fill={currentArrow.color}
                        />
                    )}
                </Layer>
                <Layer listening={selectedTool === PresentationToolsEnum.LINE}>
                    {lines.map((line) => (
                        <LineComponent
                            shapeProps={line}
                            key={line.id}
                            isSelected={line.id === selectedFigure?.id}
                            onSelect={() => {
                                if (selectedTool === PresentationToolsEnum.LINE)
                                    dispatch(
                                        setSelectedFigure({
                                            id: line.id,
                                            type: line.type,
                                        })
                                    );
                            }}
                            onChange={(newAttrs) => {
                                if (pageId) {
                                    setIsLoading(true);
                                    presentationAPIs
                                        .updateLine(
                                            pageId,
                                            stageSize.width,
                                            stageSize.height,
                                            newAttrs
                                        )
                                        .then((res) =>
                                            dispatch(updateLine(res.data))
                                        )
                                        .finally(() => setIsLoading(false));
                                }
                            }}
                        />
                    ))}
                    {currentLine && (
                        <Line
                            strokeWidth={currentLine.width}
                            stroke={currentLine.color}
                            points={currentLine.points}
                        />
                    )}
                </Layer>
                <Layer
                    listening={
                        selectedTool === PresentationToolsEnum.DOTTEDLINE
                    }
                >
                    {dottedLines.map((dottedLine) => (
                        <LineComponent
                            shapeProps={dottedLine}
                            key={dottedLine.id}
                            isSelected={dottedLine.id === selectedFigure?.id}
                            onSelect={() => {
                                if (
                                    selectedTool ===
                                    PresentationToolsEnum.DOTTEDLINE
                                )
                                    dispatch(
                                        setSelectedFigure({
                                            id: dottedLine.id,
                                            type: dottedLine.type,
                                        })
                                    );
                            }}
                            dash={[10]}
                            onChange={(newAttrs) => {
                                if (pageId) {
                                    setIsLoading(true);
                                    presentationAPIs
                                        .updateDottedLine(
                                            pageId,
                                            stageSize.width,
                                            stageSize.height,
                                            newAttrs
                                        )
                                        .then((res) =>
                                            dispatch(updateDottedLine(res.data))
                                        )
                                        .finally(() => setIsLoading(false));
                                }
                            }}
                        />
                    ))}
                    {currentDottedLine && (
                        <Line
                            strokeWidth={currentDottedLine.width}
                            stroke={currentDottedLine.color}
                            points={currentDottedLine.points}
                            dash={[10]}
                        />
                    )}
                </Layer>
            </Stage>
            {isLoading && (
                <div className={styles.loaderOverlay}>
                    <CustomLoader size={50} />
                </div>
            )}
        </div>
    );
};

export default EditingLayer;
