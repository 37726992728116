import React, { useCallback } from 'react';
import styles from './PresentationEditToolbar.module.scss';
import cursor from '@images/presentationToolCursor.svg';
import paint from '@images/presentationToolPaint.svg';
import square from '@images/PresentationToolSquare.svg';
import line from '@images/presentationToolLine.svg';
import dottedLine from '@images/presentationToolDottedLine.svg';
import arrow from '@images/presentationToolArrow.svg';
import bucket from '@images/presentationToolBucket.svg';
import Tool from '@pages/PresentationEdit/components/PresentationEditToolbar/Tool/Tool';
import Palette from '@pages/PresentationEdit/components/PresentationEditToolbar/Palette/Palette';
import {
    ITool,
    PresentationToolsEnum,
} from '@interfaces/presentationEditing.interface';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectPresentationToolColor,
    selectSelectedPresentationTool,
    setPresentationSelectedTool,
    setPresentationToolColor,
} from '@feature/presentationTools.slice';
import {
    changeFigureColor,
    deleteFigure,
    selectSelectedFigure,
    setSelectedFigure,
} from '@feature/presentationEditFigures.slice';
import { presentationAPIs } from '@APIs/presentation.apis';

const toolItems: ITool[] = [
    {
        icon: square,
        name: PresentationToolsEnum.RECTANGLE,
        title: 'Квадрат',
    },
    {
        icon: line,
        name: PresentationToolsEnum.LINE,
        title: 'Линия',
    },
    {
        icon: dottedLine,
        name: PresentationToolsEnum.DOTTEDLINE,
        title: 'Пунктир',
    },
    {
        icon: arrow,
        name: PresentationToolsEnum.ARROW,
        title: 'Указатель',
    },
];

const PresentationEditToolbar = ({
    pageId,
}: {
    pageId: string | undefined;
}) => {
    const dispatch = useAppDispatch();

    const activeTool = useAppSelector(selectSelectedPresentationTool);
    const selectedColor = useAppSelector(selectPresentationToolColor);
    const selectedFigure = useAppSelector(selectSelectedFigure);

    const onClick = useCallback((tool: PresentationToolsEnum) => {
        dispatch(setPresentationSelectedTool(tool));
        dispatch(setSelectedFigure(null));
    }, []);

    const onBucketClick = () => {
        if (selectedFigure && pageId) {
            presentationAPIs
                .deleteFigure(pageId, selectedFigure)
                .then(() => dispatch(deleteFigure(selectedFigure)));
        }
    };

    const onPaletteClick = useCallback(
        (color: string) => {
            dispatch(setPresentationToolColor(color));
            if (selectedFigure && pageId) {
                presentationAPIs
                    .changeFigureColor(
                        pageId,
                        selectedFigure.id,
                        selectedFigure.type,
                        color
                    )
                    .then(() => {
                        dispatch(
                            changeFigureColor({
                                id: selectedFigure.id,
                                type: selectedFigure.type,
                                color,
                            })
                        );
                    });
            }
        },
        [selectedFigure, activeTool]
    );

    return (
        <div className={styles.presentationEditToolbar}>
            <div className={styles.presentationEditToolbar__tools}>
                <Tool
                    icon={cursor}
                    active={activeTool === PresentationToolsEnum.CURSOR}
                    disabled={false}
                    onClick={() => onClick(PresentationToolsEnum.CURSOR)}
                />
                <Tool
                    icon={paint}
                    active={activeTool === PresentationToolsEnum.PAINT}
                    disabled={false}
                    onClick={() =>
                        onClick(
                            activeTool === PresentationToolsEnum.PAINT
                                ? PresentationToolsEnum.CURSOR
                                : PresentationToolsEnum.PAINT
                        )
                    }
                />
                <Tool
                    icon={
                        toolItems.find((tool) => tool.name === activeTool)
                            ?.icon ?? square
                    }
                    active={[
                        PresentationToolsEnum.LINE,
                        PresentationToolsEnum.ARROW,
                        PresentationToolsEnum.RECTANGLE,
                        PresentationToolsEnum.DOTTEDLINE,
                    ].includes(activeTool)}
                    disabled={false}
                    modalItems={toolItems}
                    onClick={(tool) => {
                        if (tool) onClick(tool);
                    }}
                />
                <Tool
                    icon={bucket}
                    active={false}
                    disabled={selectedFigure === null}
                    onClick={onBucketClick}
                />
                <Palette color={selectedColor} setColor={onPaletteClick} />
            </div>
        </div>
    );
};

export default PresentationEditToolbar;
