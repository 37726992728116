import React, { useEffect, useState } from 'react';

import { Layer, Image as KImage } from 'react-konva';
import Konva from 'konva';
import { ImageCache } from '@utils/utils';
import { ITransform } from '@interfaces/presentation.interface';

export function PresentationEditRect({
    id,
    imageSrc,
    containerSize,
    editing,
}: {
    id: string;
    imageSrc: string | undefined;
    isSelected: boolean;
    containerSize: {
        width: number;
        height: number;
    };
    editing: ITransform;
}): JSX.Element {
    const [img, setImage] = useState<HTMLImageElement | undefined>(undefined);
    const imageRef = React.useRef<Konva.Image>(null);

    const imageCacher = new ImageCache();

    useEffect(() => {
        if (imageSrc) {
            imageCacher.load(imageSrc).then((image) => {
                setImage(image);
            });
        }
    }, [id]);

    useEffect(() => {
        if (img) {
            updateSize(imageRef.current, img, containerSize);
        }
    }, [img]);

    return (
        <Layer>
            <KImage
                ref={imageRef}
                image={img}
                onContextMenu={(e) => {
                    e.evt.preventDefault();
                    e.evt.stopPropagation();
                }}
            />
        </Layer>
    );
}

function updateSize(
    konva_image: Konva.Image | null,
    html_image: HTMLImageElement,
    container: {
        width: number;
        height: number;
    }
) {
    if (konva_image) {
        const scaleX = container.width / html_image.width;
        const scaleY = container.height / html_image.height;

        const scale = Math.max(scaleX, scaleY);

        const size = {
            width: html_image.width * scale,
            height: html_image.height * scale,
        };

        const offset = {
            x: size.width / 2,
            y: size.height / 2,
        };

        const position = {
            x: container.width / 2,
            y: container.height / 2,
        };

        konva_image.size(size);
        konva_image.offset(offset);
        konva_image.position(position);
    }
}
