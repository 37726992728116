export enum PresentationToolsEnum {
    CURSOR = 'CURSOR',
    PAINT = 'PAINT',
    RECTANGLE = 'RECTANGLE',
    LINE = 'LINE',
    ARROW = 'ARROW',
    DOTTEDLINE = 'DOTTEDLINE',
}

export enum PresentationFiguresEnum {
    CURVE = 'curve',
    RECTANGLE = 'rectangle',
    ARROW = 'arrow',
    LINE = 'line',
    DOTTEDLINE = 'dottedLine',
}

export interface ITool {
    name: PresentationToolsEnum;
    icon: string;
    title: string;
}

export interface ILine {
    type: PresentationFiguresEnum;
    id: string;
    color: string;
    width: number;
    points: number[];
}

export interface IRectangle {
    type: PresentationFiguresEnum;
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    stroke: string;
    strokeWidth: number;
    rotation: number;
}

export interface IArrow {
    type: PresentationFiguresEnum;
    id: string;
    startPoint: { x: number; y: number };
    endPoint: { x: number; y: number };
    color: string;
    width: number;
}
