import React, { useCallback, useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import {
    IRectangle,
    PresentationFiguresEnum,
} from '@interfaces/presentationEditing.interface';
import useCursor from '@hooks/useCursor';
import Konva from 'konva';

interface RectangleProps {
    shapeProps: {
        id: string;
        x: number;
        y: number;
        width: number;
        height: number;
        stroke: string;
        strokeWidth: number;
        rotation: number;
        type: PresentationFiguresEnum;
    };
    isSelected: boolean;
    onSelect: () => void;
    onChange: (newAttrs: IRectangle) => void;
}

const RectangleComponent: React.FC<RectangleProps> = ({
    shapeProps,
    isSelected,
    onSelect,
    onChange,
}) => {
    const shapeRef = useRef<Konva.Rect>(null);
    const trRef = useRef<Konva.Transformer>(null);

    useEffect(() => {
        if (isSelected && shapeRef.current) {
            trRef?.current?.nodes([shapeRef.current]);
            trRef?.current?.getLayer()?.batchDraw();
        }
    }, [isSelected]);

    useCursor(shapeRef, isSelected);

    const handleClick = (e: KonvaEventObject<MouseEvent>) => {
        onSelect();
        const container = e.target.getStage()?.container();
        if (container) {
            container.style.cursor = 'move';
        }
    };

    const checkBoundaries = useCallback(
        (pos: { x: number; y: number }) => {
            const { x, y } = pos;
            const stage = shapeRef.current?.getStage();
            if (!stage) return pos;

            const stageWidth = stage.width();
            const stageHeight = stage.height();
            const shapeWidth = shapeProps.width;
            const shapeHeight = shapeProps.height;
            const theta = shapeProps.rotation * (Math.PI / 180);

            const newWidth =
                Math.abs(shapeWidth * Math.cos(theta)) +
                Math.abs(shapeHeight * Math.sin(theta));
            const newHeight =
                Math.abs(shapeWidth * Math.sin(theta)) +
                Math.abs(shapeHeight * Math.cos(theta));

            const newX = Math.max(
                newWidth / 2,
                Math.min(x, stageWidth - newWidth / 2)
            );
            const newY = Math.max(
                newHeight / 2,
                Math.min(y, stageHeight - newHeight / 2)
            );

            return {
                x: newX,
                y: newY,
            };
        },
        [shapeProps.rotation, shapeProps.width, shapeProps.height]
    );

    return (
        <>
            <Rect
                onClick={handleClick}
                onTap={handleClick}
                ref={shapeRef}
                {...shapeProps}
                fill={'transparent'}
                offsetX={shapeProps.width / 2}
                offsetY={shapeProps.height / 2}
                draggable
                onDragMove={(e) => {
                    const pos = e.target.position();
                    const newPos = checkBoundaries(pos);
                    e.target.position(newPos);
                }}
                onDragEnd={(e) => {
                    const pos = e.target.position();
                    const newPos = checkBoundaries(pos);
                    onChange({
                        ...shapeProps,
                        x: newPos.x,
                        y: newPos.y,
                    });
                }}
                onTransformEnd={() => {
                    const node = shapeRef.current;
                    if (node) {
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        node.scaleX(1);
                        node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(5, node.height() * scaleY),
                            rotation: node.rotation(),
                        });
                    }
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (
                            Math.abs(newBox.width) < 5 ||
                            Math.abs(newBox.height) < 5
                        ) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    rotationSnaps={[0, 90, 180, 270]}
                />
            )}
        </>
    );
};

export default RectangleComponent;
