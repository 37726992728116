import React, { useRef, useState, useCallback } from 'react';
import { Arrow, Group } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { IArrow } from '@interfaces/presentationEditing.interface';
import useCursor from '@hooks/useCursor';
import Konva from 'konva';
import PointComponent from '@pages/PresentationEdit/components/EditingLayer/PointComponent/PointComponent';

interface ArrowProps {
    shapeProps: IArrow;
    isSelected: boolean;
    onSelect: () => void;
    onChange: (newAttrs: IArrow) => void;
}

const ArrowComponent: React.FC<ArrowProps> = ({
    shapeProps,
    isSelected,
    onSelect,
    onChange,
}) => {
    const shapeRef = useRef<Konva.Arrow>(null);
    const groupRef = useRef<Konva.Group>(null);
    const moveDistance = useRef<[number, number, number, number]>([0, 0, 0, 0]);

    const [tempPoints, setTempPoints] = useState<{
        startPoint: { x: number; y: number };
        endPoint: { x: number; y: number };
    }>({ startPoint: shapeProps.startPoint, endPoint: shapeProps.endPoint });

    useCursor(shapeRef, isSelected);

    const handlePointDragMove = useCallback(
        (type: 'endPoint' | 'startPoint', e: KonvaEventObject<DragEvent>) => {
            if (shapeRef.current) {
                setTempPoints((prevPoints) => ({
                    ...prevPoints,
                    [type]: {
                        x: e.target.x(),
                        y: e.target.y(),
                    },
                }));
                e.cancelBubble = true;
            }
        },
        []
    );

    const handleGroupDragStart = useCallback(() => {
        const group = groupRef.current;
        if (group) {
            const stage = group.getStage();
            const stageWidth = stage?.width() ?? 0;
            const stageHeight = stage?.height() ?? 0;
            moveDistance.current = [
                -Math.min(tempPoints.startPoint.x, tempPoints.endPoint.x),
                stageWidth -
                    Math.max(tempPoints.startPoint.x, tempPoints.endPoint.x),
                -Math.min(tempPoints.startPoint.y, tempPoints.endPoint.y),
                stageHeight -
                    Math.max(tempPoints.startPoint.y, tempPoints.endPoint.y),
            ];
        }
    }, [tempPoints]);

    const handleGroupDragMove = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            const group = groupRef.current;
            if (group) {
                const newX = group.x() + e.evt.movementX;
                const newY = group.y() + e.evt.movementY;

                if (
                    newX >= moveDistance.current[0] &&
                    newX <= moveDistance.current[1] &&
                    newY >= moveDistance.current[2] &&
                    newY <= moveDistance.current[3]
                ) {
                    group.position({ x: newX, y: newY });
                } else {
                    const boundedX = Math.max(
                        moveDistance.current[0],
                        Math.min(moveDistance.current[1], newX)
                    );
                    const boundedY = Math.max(
                        moveDistance.current[2],
                        Math.min(moveDistance.current[3], newY)
                    );
                    group.position({ x: boundedX, y: boundedY });
                }
                group.getLayer()?.batchDraw();
            }
        },
        []
    );

    const handleDragEnd = useCallback(() => {
        if (groupRef.current) {
            const groupPosition = groupRef.current.position();
            const newPoints = {
                startPoint: {
                    x: tempPoints.startPoint.x + groupPosition.x,
                    y: tempPoints.startPoint.y + groupPosition.y,
                },
                endPoint: {
                    x: tempPoints.endPoint.x + groupPosition.x,
                    y: tempPoints.endPoint.y + groupPosition.y,
                },
            };
            onChange({
                ...shapeProps,
                startPoint: newPoints.startPoint,
                endPoint: newPoints.endPoint,
            });
            setTempPoints(newPoints);
            groupRef.current.position({ x: 0, y: 0 });
        }
    }, [onChange, shapeProps, tempPoints]);

    return (
        <React.Fragment>
            <Group
                ref={groupRef}
                draggable
                onDragEnd={handleDragEnd}
                onDragStart={handleGroupDragStart}
                onDragMove={handleGroupDragMove}
            >
                <Arrow
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    strokeWidth={shapeProps.width}
                    points={[
                        tempPoints.startPoint.x,
                        tempPoints.startPoint.y,
                        tempPoints.endPoint.x,
                        tempPoints.endPoint.y,
                    ]}
                    fill={shapeProps.color}
                    stroke={shapeProps.color}
                />
                {isSelected && (
                    <React.Fragment>
                        <PointComponent
                            x={tempPoints.startPoint.x}
                            y={tempPoints.startPoint.y}
                            onDragMove={(e) =>
                                handlePointDragMove('startPoint', e)
                            }
                            onDragEnd={handleDragEnd}
                        />
                        <PointComponent
                            x={tempPoints.endPoint.x}
                            y={tempPoints.endPoint.y}
                            onDragMove={(e) =>
                                handlePointDragMove('endPoint', e)
                            }
                            onDragEnd={handleDragEnd}
                        />
                    </React.Fragment>
                )}
            </Group>
        </React.Fragment>
    );
};

export default ArrowComponent;
