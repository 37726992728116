import React from 'react';
import { CollectionStatus } from '../CollectionStatus/CollectionStatus';

import styles from './StatusInfo.module.scss';
import { ICollectionStatus } from '@feature/patient.slice';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';

export const StatusInfo = ({
    name,
    statuses,
    id,
    onCollectionChange,
}: {
    name: string;
    statuses: ICollectionStatus[];
    id: string;
    onCollectionChange: (id: string) => void;
}): JSX.Element => {
    return (
        <div className={styles.statusInfo}>
            <div className={styles.statusInfo__title}>
                Не забудьте заполнить следующие фотоколлекции для полной
                комплектации презентации
            </div>
            <div className={styles.statusInfo__status}>
                {statuses.length > 0 ? (
                    statuses.map((el, ind) => {
                        return (
                            <div
                                className={styles.statusInfo__statusBox}
                                key={el.name}
                            >
                                <CollectionStatus
                                    name={el.name}
                                    status={
                                        el.status === 'completed'
                                            ? 'completed'
                                            : el.name === name
                                            ? 'in_process'
                                            : el.status !== 'in_process'
                                            ? el.status
                                            : 'waiting_for_filled'
                                    }
                                    onClick={() =>
                                        onCollectionChange(el.id ?? id)
                                    }
                                />

                                {ind !== statuses.length - 1 && (
                                    <div
                                        className={
                                            styles.statusInfo__statusDivider
                                        }
                                    ></div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className={styles.statusInfo__loadingBox}>
                        <CustomLoader size={36} />
                    </div>
                )}
            </div>
        </div>
    );
};
