import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { ROUTES } from '@constants/routes';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectSelectedPatient } from '@store/feature/patient.slice';
import { Link } from 'react-router-dom';
import { PhotoCollectionApis } from '@APIs/photo-collection.apis';
import styles from './PhotoCollectionsTable.module.scss';
import { Modal } from '@components/common/ModalWindow/ModalWindow';
import { constructLocalDate } from '@utils/constructLocalDate';

export function PhotoCollectionsTableColumns(
    onRemoval: (id: string) => void
): GridColDef[] {
    const selectedPatient = useAppSelector(selectSelectedPatient);

    return [
        {
            field: 'name',
            headerName: 'Название',
            disableColumnMenu: true,
            width: 350,
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                return (
                    <div className={`${styles.Row} ${styles.Title}`}>
                        {params.row.title}
                    </div>
                );
            },
        },
        {
            field: 'previews',
            headerName: 'Дата',
            disableColumnMenu: true,
            width: 280,
            align: 'left',
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                return <div>{constructLocalDate(params.row.created_at)}</div>;
            },
            valueGetter: (params: GridRenderCellParams): number => {
                return params.row.created_at;
            },
            sortComparator: (v1: number, v2: number): number => {
                return v1 - v2;
            },
        },
        {
            field: 'change',
            headerName: 'Действия',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                const [isModalOpen, setOpenModal] = useState(false);
                return (
                    <>
                        <Link
                            to={{
                                pathname: ROUTES.PATIENTS.$(
                                    selectedPatient?.id
                                ).PHOTOS.$(params.row.id).END,
                                state: {
                                    patientId: selectedPatient?.id ?? null,
                                    collectionId: params.row.id,
                                },
                            }}
                        >
                            <div className={styles.Button}>Просмотр</div>
                        </Link>
                        <div
                            className={styles.Button}
                            onClick={() => setOpenModal(true)}
                        >
                            Удалить
                        </div>
                        <Modal
                            message={
                                'Вы действительно хотите удалить выбранную фотоколлекцию?'
                            }
                            isOpen={isModalOpen}
                            onClose={() => setOpenModal(false)}
                            btnLabel="Удалить"
                            btnAction={() => {
                                selectedPatient?.id &&
                                    PhotoCollectionApis.deleteCollection(
                                        params.row.id
                                    ).then(() => {
                                        onRemoval(params.row.id);
                                    });
                            }}
                        />
                    </>
                );
            },
        },
    ];
}
