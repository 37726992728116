import React from 'react';

import photoInProgress from '@images/photo_in_progress.svg';
import photoWaiting from '@images/photo_waiting.svg';
import photoDone from '@images/photo_done.svg';
import photoPartlyFull from '@images/photo_partly_full.svg';
import styles from './CollectionStatus.module.scss';

export type StatusProps = {
    status: 'completed' | 'waiting_for_filled' | 'in_process' | null;
    name: string;
    onClick: () => void;
};

type StateDescription = {
    icon: string;
    state: string;
};

const statusStates: Record<string, StateDescription> = {
    completed: { icon: photoDone, state: 'Завершено' },
    waiting_for_filled: { icon: photoWaiting, state: 'Ожидает заполнения' },
    in_process: { icon: photoInProgress, state: 'В процессе' },
    partially_filled: { icon: photoPartlyFull, state: 'Частично заполнено' },
};

export const CollectionStatus = ({
    status,
    name,
    onClick,
}: StatusProps): JSX.Element => {
    return (
        <div className={styles.Status} onClick={onClick}>
            <img
                src={
                    status === null
                        ? statusStates['waiting_for_filled'].icon
                        : statusStates[status].icon
                }
                className={styles.Icon}
                alt={'status icon'}
            />
            <div className={styles.Description}>
                <div className={styles.Name}>{name}</div>
                <div className={styles.State}>
                    {status === null
                        ? statusStates['waiting_for_filled'].state
                        : statusStates[status].state}
                </div>
            </div>
        </div>
    );
};
