import React from 'react';
import { ICollectionElement } from '@interfaces/collection-element.interface';

import styles from './ElementInfo.module.scss';
import PresentationsListInfo from '@pages/PhotoMatching/components/PresentationsListInfo/PresentationsListInfo';

export const ElementInfo = ({
    element,
}: {
    element: ICollectionElement | undefined;
}): JSX.Element => {
    return (
        <div className={styles.elementInfo}>
            {element && (
                <div className={styles.elementInfo__content}>
                    <div>
                        <div className={styles.elementInfo__title}>
                            {element.name}
                        </div>
                        <div className={styles.elementInfo__description}>
                            <p>{element.description}</p>
                        </div>
                        <PresentationsListInfo
                            presentations={element.presentations}
                        />
                    </div>
                    <div className={styles.elementInfo__imagesContainer}>
                        {element.photo?.preview ? (
                            <div className={styles.elementInfo__patientImage}>
                                <img src={element.photo?.preview} alt="" />
                            </div>
                        ) : (
                            <div className={styles.elementInfo__emptyImage}>
                                <h5>Место для фото</h5>
                            </div>
                        )}
                        <div className={styles.elementInfo__templateImage}>
                            <img src={element.previewURL} alt="" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
