import React, { useState, useCallback } from 'react';
import { updatePageImage } from '@utils/presentation';
import { useDebounce } from '@hooks/useDebounce';
import { PresentationEditRect } from '@pages/PresentationEdit/components/PresentationEditRect/PresentationEditRect';
import { RectangleKonvaBase } from '@pages/PresentationEdit/components/RectangleKonvaBase/RectangleKonvaBase';
import { ITransform, IRect } from '@interfaces/presentation.interface';

type RectOption = 'ROTATE' | 'ZOOM' | 'TRANSFORM';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RectComponentOptions = React.createContext<{ [key: string]: any }>(
    {}
);

export function RectComponent({
    rect,
    rectIndex,
    update,
    selectElement,
    selectedElementId,
}: {
    rect: IRect;
    rectIndex: number;
    update: (transform: ITransform, rectIndex: number) => void;
    selectElement: (id: string) => void;
    selectedElementId: string;
}): JSX.Element {
    const [option, setOption] = useState<RectOption>('TRANSFORM');

    const getOption = useCallback(() => {
        return option;
    }, [option]);

    const percentOriginalHeight = rect.box.height / 1080;
    const percentOriginalWidth = rect.box.width / 1920;

    const stageHeight =
        document.getElementsByClassName('presentation_page')[0].clientHeight;
    const stageWidth =
        document.getElementsByClassName('presentation_page')[0].clientWidth;

    const percentBoxX = rect.box.x / 1920;
    const percentBoxY = rect.box.y / 1080;

    const radius = rect.cornerRadius;

    const sendChange = useCallback(
        useDebounce(
            (
                id: string,
                transform: {
                    value: string;
                    rotate: number;
                    scale: number;
                    transform: {
                        x: number;
                        y: number;
                    };
                    reflection: {
                        x: number;
                        y: number;
                    };
                }
            ) => {
                if (id) {
                    updatePageImage(id, transform);
                }
            },
            210
        ),
        []
    );

    const onTrasformChanged = (
        selectedElementId: string,
        transform: ITransform
    ) => {
        sendChange(selectedElementId, {
            ...transform,
            transform: {
                x: transform.transform.x,
                y: transform.transform.y,
            },
        });
        update(
            {
                ...transform,
                transform: {
                    x: transform.transform.x,
                    y: transform.transform.y,
                },
            },
            rectIndex
        );
    };

    const onTransformChanges = useCallback(
        (transform: ITransform) => onTrasformChanged(rect._id, transform),
        [rect._id]
    );

    const initialTransform = () => {
        return {
            rotate: rect.editing?.rotate ?? 0,
            scale: rect.editing?.scale ?? 1,
            transform: {
                x: rect.editing?.transform.x ?? 0,
                y: rect.editing?.transform.y ?? 0,
            },
            reflection: {
                x: rect.editing?.reflection?.x ?? 1,
                y: rect.editing?.reflection?.y ?? 1,
            },
        };
    };

    return rect.locked ? (
        <div
            id={rect._id}
            style={{
                overflow: 'hidden',
                position: 'absolute',
                marginLeft: stageWidth * percentBoxX,
                marginTop: stageHeight * percentBoxY,
                width: stageWidth * percentOriginalWidth,
                height: percentOriginalHeight * stageHeight,
                borderRadius: radius ?? 0,
            }}
        />
    ) : (
        <RectComponentOptions.Provider
            value={{
                getOption,
                setOption,
            }}
        >
            <div id={rect._id} onClick={() => selectElement(rect._id)}>
                {!rect.url ? (
                    <div
                        className={'empty_rect_component'}
                        style={{
                            marginLeft: stageWidth * percentBoxX,
                            marginTop: stageHeight * percentBoxY,
                            width: stageWidth * percentOriginalWidth - 4,
                            height: percentOriginalHeight * stageHeight - 4,
                        }}
                    >
                        <span
                            style={{
                                fontSize: `${
                                    (percentOriginalHeight * stageHeight - 4) /
                                    23
                                }px`,
                                lineHeight: `${
                                    (percentOriginalHeight * stageHeight - 4) /
                                    19
                                }px`,
                            }}
                        >
                            Загрузите фото в фото-
                            <br />
                            коллекции
                        </span>
                    </div>
                ) : (
                    <div
                        className={
                            rect.editable
                                ? selectedElementId == rect._id
                                    ? 'rect_component_selected'
                                    : 'rect_component'
                                : 'rect_component_static'
                        }
                        style={{
                            marginLeft: stageWidth * percentBoxX,
                            marginTop: stageHeight * percentBoxY,
                            width: stageWidth * percentOriginalWidth - 4,
                            height: percentOriginalHeight * stageHeight - 4,
                        }}
                    >
                        <div
                            className="image_presentation_container"
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                            }}
                            id={rect._id}
                            key={rect._id}
                        >
                            <RectangleKonvaBase
                                isEditable={rect.editable}
                                isActive={selectedElementId == rect._id}
                                initialTransform={initialTransform()}
                                onTransformChanges={onTransformChanges}
                            >
                                <PresentationEditRect
                                    id={rect.box.id}
                                    containerSize={{
                                        width:
                                            stageWidth * percentOriginalWidth -
                                            4,
                                        height:
                                            percentOriginalHeight *
                                                stageHeight -
                                            4,
                                    }}
                                    editing={rect.editing!}
                                    imageSrc={rect.url}
                                    isSelected={selectedElementId == rect._id}
                                />
                            </RectangleKonvaBase>
                        </div>
                    </div>
                )}
            </div>
        </RectComponentOptions.Provider>
    );
}
