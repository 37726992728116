import { ICollectionElement } from '@interfaces/collection-element.interface';
import React, { useEffect } from 'react';
import PhotoFile from '../PhotoFile/PhotoFile';
import style from './PhotoMatchingElementsArea.module.scss';
import { SetCollectionStatusFunction } from '@interfaces/collection.interface';

interface IProps {
    markedTemplates: string[];
    templateSelected?: ICollectionElement;
    onMark: (id: string) => void;
    onTemplateSelected: (element: ICollectionElement) => void;
    setActiveElement: (element: ICollectionElement) => void;
    elements?: ICollectionElement[];
    setCollectionStatus: SetCollectionStatusFunction;
}

function PhotoMatchingElementsArea({
    markedTemplates,
    onTemplateSelected,
    setActiveElement,
    elements,
    onMark,
    templateSelected,
    setCollectionStatus,
}: IProps): JSX.Element {
    useEffect(() => {
        if (elements) {
            if (elements.every((element) => element.photo)) {
                setCollectionStatus('completed');
            } else if (elements.some((element) => !element.photo)) {
                setCollectionStatus('waiting_for_filled');
            }
        }
    }, [elements]);
    return elements?.length === 0 ? (
        <div className={style.photoMatchingElementsArea_empty}>
            <span>Шаблонов нет</span>
        </div>
    ) : (
        <div className={style.photoMatchingElementsArea}>
            {elements?.map((element: ICollectionElement) => {
                return (
                    <PhotoFile
                        isChecked={markedTemplates.includes(element.id)}
                        file={{
                            id: element.id,
                            url: element.previewURL,
                            name: element.name,
                            timeCreated: 0,
                            selectedUrl: element.photo?.preview,
                        }}
                        isTemplate={true}
                        isSelected={templateSelected?.id === element.id}
                        onClick={() => {
                            onTemplateSelected(element);
                        }}
                        onMouseEnter={() => {
                            setActiveElement(element);
                        }}
                        onMouseLeave={() => {
                            const element = elements.filter(
                                (file) => templateSelected?.id === file.id
                            );
                            if (element.length > 0) {
                                setActiveElement(element[0]);
                            }
                        }}
                        onMark={() => onMark(element.id)}
                        key={element.id}
                    />
                );
            })}
        </div>
    );
}

export default PhotoMatchingElementsArea;
