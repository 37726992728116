import { IPhotoFile } from '@interfaces/photo.interface';
import React from 'react';
import PhotoFile from '../PhotoFile/PhotoFile';
import styles from './PhotoMatchingArea.module.scss';
import { PhotoFileUploader } from '../PhotoFileUploader/PhotoFileUploader';

interface IProps {
    componentState: {
        patientId: string;
    };
    collectionId: string;
    onSuccessUpload: () => void;
    files: IPhotoFile[];
    onPhotoSelected: (file: IPhotoFile) => void;
    photoSelected?: IPhotoFile;
}

function PhotoMatchingArea({
    componentState,
    collectionId,
    onSuccessUpload,
    files,
    onPhotoSelected,
    photoSelected,
}: IProps): JSX.Element {
    return (
        <div className={styles.photoMatchingArea}>
            <PhotoFileUploader
                identifier={componentState.patientId}
                collectionId={collectionId}
                onSuccessUpload={onSuccessUpload}
            />
            {files.map((file: IPhotoFile, index: number) => {
                return (
                    <PhotoFile
                        isChecked={false}
                        file={file}
                        isTemplate={false}
                        isSelected={photoSelected?.id === file.id}
                        onClick={() => {
                            onPhotoSelected(file);
                        }}
                        onMouseEnter={() => {
                            // empty
                        }}
                        onMouseLeave={() => {
                            // setActiveElement(undefined);
                        }}
                        onMark={() => {
                            //empty
                        }}
                        key={index}
                    />
                );
            })}
        </div>
    );
}

export default PhotoMatchingArea;
