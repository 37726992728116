import React, { useEffect } from 'react';
import './PresentationEditList.scss';
import { IPage } from '@interfaces/presentation.interface';
import RowElement from '@pages/PresentationEdit/components/PresentationEditList/RowElement/RowElement';

type PresentationEditListProps = {
    pages: IPage[];
    setSelectedPage: (index: number) => void;
    setPageVisibility: (pageId: string, isHidden: boolean) => void;
    selectedIndex: number;
};

const PresentationEditList = ({
    setSelectedPage,
    selectedIndex,
    pages,
    setPageVisibility,
}: PresentationEditListProps): JSX.Element => {
    useEffect(() => {
        const setIndex = (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown' && selectedIndex + 1 < pages.length) {
                setSelectedPage(selectedIndex + 1);
            } else if (e.key === 'ArrowUp' && selectedIndex - 1 >= 0) {
                setSelectedPage(selectedIndex - 1);
            }
        };
        window.addEventListener('keydown', setIndex);

        return () => {
            window.removeEventListener('keydown', setIndex);
        };
    }, [selectedIndex, pages]);

    return (
        <div className="list_container">
            {pages.map((page, index) => (
                <RowElement
                    isHidden={page.is_hidden}
                    isSelected={index === selectedIndex}
                    onClick={() => setSelectedPage(index)}
                    index={index}
                    key={page.id}
                    onEyeClick={() =>
                        setPageVisibility(page.id, !page.is_hidden)
                    }
                />
            ))}
        </div>
    );
};

export default PresentationEditList;
