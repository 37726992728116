import { IPhotoFile } from '@interfaces/photo.interface';
import React, { useEffect, useRef } from 'react';
import style from './PhotoFile.module.scss';
import dateFormat from 'dateformat';
import checkmark from '@images/check-mark_white.svg';
import { classes } from '@utils/classNames';

type PhotoFileProps = {
    isChecked: boolean;
    file: IPhotoFile;
    isTemplate: boolean;
    isSelected: boolean;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onMark: () => void;
};

const PhotoFile = ({
    isChecked,
    file,
    isTemplate,
    isSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMark,
}: PhotoFileProps): JSX.Element => {
    const selectElement: React.MouseEventHandler<HTMLDivElement> = () => {
        onClick();
    };

    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isSelected && cardRef.current)
            cardRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
    }, [isSelected]);

    return (
        <div
            ref={cardRef}
            className={style.Container}
            onClick={selectElement}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
            style={isSelected ? { border: '1px solid #3B82F6' } : {}}
        >
            <div
                className={
                    isTemplate ? style.SubContainer : style.SubContainerReversed
                }
            >
                <div className={style.ImageContainer}>
                    {file.selectedUrl && (
                        <div
                            className={classes(style.CheckboxWrapper, {
                                [style.Shown]: isChecked,
                            })}
                            onClick={() => {
                                onMark();
                            }}
                        >
                            <div
                                className={classes(style.FakeCheckbox, {
                                    [style.Checked]: isChecked,
                                })}
                            >
                                {isChecked ? (
                                    <img
                                        className={style.Checkmark}
                                        src={checkmark}
                                        alt={'Фото зубов'}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    )}
                    <img
                        src={file.url}
                        alt=""
                        className={style.Image}
                        style={
                            isTemplate
                                ? { objectFit: 'contain' }
                                : { objectFit: 'cover' }
                        }
                    />
                </div>
                {isTemplate ? (
                    <div
                        className={style.ImageContainerTemplate}
                        style={isSelected ? { borderColor: '#3B82F6' } : {}}
                    >
                        {file.selectedUrl ? (
                            <img
                                src={file.selectedUrl}
                                alt=""
                                className={style.Image}
                            />
                        ) : (
                            <h5>Место для фото</h5>
                        )}
                    </div>
                ) : (
                    <div className={style.TitleContainer}>
                        <div className={style.Title}>
                            <p>{file.name}</p>
                        </div>
                        <div className={style.Date}>
                            {dateFormat(
                                new Date(file.timeCreated),
                                'd mmmm yyyy'
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PhotoFile;
