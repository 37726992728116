import React from 'react';
import styles from './PhotoCollectionsTable.module.scss';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { PhotoCollectionsTableColumns } from './PhotoCollectionsTableColumns';
import { makeStyles } from '@material-ui/core';
import { ICollectionPreview } from '@interfaces/collection.interface';

const row =
    '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell';
const rowHover = '&.MuiDataGrid-root .MuiDataGrid-row:hover';
const cell =
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus';
const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            overflow: 'inherit',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
            overflow: 'visible',
        },
        [cell]: {
            overflow: 'visible',
        },
        [row]: {
            overflow: 'visible',
            outline: 'none',
        },
        [rowHover]: {
            backgroundColor: '#b2b2b214',
        },
        '& .MuiDataGrid-columnHeaders': {
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#000000',
        },
    },
});

export const PhotoCollectionsTable = ({
    collections,
    isLoading = false,
    onRemoval,
}: {
    collections: ICollectionPreview[];
    isLoading?: boolean;
    onRemoval: (id: string) => void;
}): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={styles.Container}>
            <DataGrid
                components={{
                    NoRowsOverlay: () => {
                        return (
                            <div className="grid_no-rows-overlay">
                                Список фотоколлекций пуст
                            </div>
                        );
                    },
                }}
                className={classes.root}
                columns={PhotoCollectionsTableColumns(onRemoval)}
                rows={[...collections].sort(
                    (a, b) => b.created_at - a.created_at
                )}
                disableColumnMenu={true}
                loading={isLoading}
                hideFooterSelectedRowCount={true}
                getRowHeight={() => 'auto'}
                disableSelectionOnClick={true}
                getRowId={(row) => row.id}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
};

export default PhotoCollectionsTable;
