import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { ROUTES } from '@constants/routes';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { PhotoCollection } from './child/PhotoCollection';
import styles from './PhotoTemplatesPageTable.module.scss';
import { setPresentationId } from '@feature/presentationId.slice';

export function PhotoTemplatesPageTableColumns(): GridColDef[] {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleFillButtonClick = (
        patientId: string,
        collectionId: string,
        rowId: string
    ) => {
        dispatch(setPresentationId(null));
        history.push(
            ROUTES.PATIENTS.$(patientId).PHOTOS.$(collectionId).FILL.$(rowId)
                .END
        );
    };

    const elements: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Информация',
            disableColumnMenu: true,
            width: 150,
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                return (
                    <div className={styles.TitleContainer}>
                        <div className={styles.Title}>{params.row.title}</div>
                        <div className={styles.TagArea}>
                            <div className={styles.Tag}>dilab</div>
                        </div>
                    </div>
                );
            },
        },
        {
            field: 'previews',
            headerName: 'Элементы',
            disableColumnMenu: true,
            flex: 1,
            align: 'center',
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                return <PhotoCollection elements={params.row.elements} />;
            },
        },
        {
            field: 'change',
            headerName: 'Действия',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            width: 105,
            renderCell: function EditButton(
                params: GridRenderCellParams
            ): JSX.Element {
                const { collectionId, patientId } = useParams<{
                    patientId: string;
                    collectionId: string;
                }>();
                return (
                    <div
                        className={styles.FillButton}
                        onClick={() =>
                            handleFillButtonClick(
                                patientId!,
                                collectionId!,
                                params.row.id
                            )
                        }
                    >
                        <span>Заполнить</span>
                    </div>
                );
            },
        },
    ];

    return elements;
}
