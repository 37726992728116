import React, { useCallback, useState } from 'react';
import styles from './PhotoCollectionsHeader.module.scss';
import { Menu, MenuItem } from '@material-ui/core';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import { PhotoCollectionApis } from '@APIs/photo-collection.apis';
import { toast } from 'react-toastify';
import { ITemplate } from '@interfaces/collection.interface';

const PhotoCollectionsHeader = ({
    createCollection,
}: {
    createCollection: (id: string) => void;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [availablePatterns, setAvailablePatterns] = useState<ITemplate[]>([]);
    const isOpen = Boolean(anchorEl);

    const getCollectionTypes = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);

            if (availablePatterns.length === 0) {
                setIsLoading(true);
                PhotoCollectionApis.getTemplates()
                    .then(({ data }) => {
                        setAvailablePatterns(data);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.error(error.response.data.message);
                        }
                    });
            }
        },
        [availablePatterns]
    );

    return (
        <div className={styles.Header}>
            <h2 className={styles.Title}>Фотоколлекции</h2>
            <button
                className={styles.Button}
                id="add-pattern-button"
                aria-controls={isOpen ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                onClick={getCollectionTypes}
            >
                <div className={styles.ButtonIcon}></div>
            </button>
            <Menu
                id="add-pattern-button"
                aria-labelledby="add-pattern-button"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={isOpen}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={0}
                PaperProps={{ className: styles.Menu }}
            >
                {isLoading ? (
                    <MenuItem>
                        <CustomLoader />
                    </MenuItem>
                ) : (
                    availablePatterns.map((pattern) => (
                        <MenuItem
                            onClick={() => createCollection(pattern._id)}
                            key={pattern.key}
                            style={{ width: '148px' }}
                        >
                            {pattern.title}
                        </MenuItem>
                    ))
                )}
            </Menu>
        </div>
    );
};

export default PhotoCollectionsHeader;
