import React, { useMemo, useState } from 'react';
import styles from './Tool.module.scss';
import { classes } from '@utils/classNames';
import {
    ITool,
    PresentationToolsEnum,
} from '@interfaces/presentationEditing.interface';
import { ReactComponent as Chevron } from '@images/presentationToolChevron.svg';

const Tool = ({
    icon,
    active,
    disabled,
    modalItems,
    onClick,
}: {
    icon: string;
    active: boolean;
    disabled: boolean;
    modalItems?: ITool[];
    onClick: (tool?: PresentationToolsEnum) => void;
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleModalItemClick = (
        e: React.MouseEvent,
        tool: PresentationToolsEnum
    ) => {
        e.stopPropagation();
        onClick(tool);
    };

    const modalHeight = useMemo(
        () => 21 * (modalItems?.length ?? 0),
        [modalItems]
    );
    return (
        <div
            className={classes(styles.tool, {
                [styles.tool_active]: active,
                [styles.tool_disabled]: disabled,
                [styles.tool_opened]: isOpened,
            })}
            onClick={
                !modalItems
                    ? () => onClick()
                    : () => setIsOpened((prevState) => !prevState)
            }
        >
            <div
                className={classes(styles.tool__icon, {
                    [styles.tool__icon_active]: active,
                })}
            >
                <img src={icon} alt="" />
                {!!modalItems && (
                    <Chevron
                        style={{
                            transform: isOpened ? '' : 'rotate(180deg)',
                            transition: 'transform 0.3s ease',
                        }}
                    />
                )}
            </div>
            <div
                className={styles.tool__modal}
                style={{
                    height: isOpened ? `${modalHeight}px` : '0',
                }}
            >
                {!!modalItems &&
                    modalItems.map((item) => (
                        <div
                            className={styles.tool__modalItem}
                            key={item.name}
                            onClick={(e) => handleModalItemClick(e, item.name)}
                        >
                            <img
                                src={item.icon}
                                alt={item.name}
                                className={styles.tool__modalItemImg}
                            />
                            <span className={styles.tool__modalItemText}>
                                {item.title}
                            </span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Tool;
